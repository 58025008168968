.tooltip-container {
  position: relative;
  display: inline-block;

  sup {
    top: -1rem !important;
    margin: 0.5rem;
  }
}

.tooltip-text {
  position: absolute;
  width: 100%;
  bottom: 100%;
  min-width: 200px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  font-size: 14px;
  font-style: italic;
  z-index: 10;

  // style for tooltip arrow
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

  &.bottom {
    &::after {
      top: -10px;
      bottom: auto;
      border-color: transparent transparent #333 transparent;
    }
    bottom: auto;
    margin-top: 1rem;
  }
  &.left {
    width: auto;
    bottom: auto;
    &::after {
      top: 0%;
      left: 100%;
      margin-left: 0;
      margin-top: -5px;
      border-color: transparent #333 transparent transparent;
    }
    left: auto;
    right: 45%;
    top: 50%;
    margin-left: 1rem;
  }
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

// reponsive
@media (max-width: 768px) {
  .tooltip-container {
    sup {
      top: 0rem !important;
    }
  }
  .tooltip-text {
    &.left {
      right: 0;
      left: 30%;
      &::after {
        left: -10px;
        margin-left: 0;
        margin-top: -5px;
        border-color: transparent #333 transparent transparent;
      }
    }
  }
}
