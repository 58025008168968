.exit-title,
.exit-text {
  color: #00556e;
  font-family: Gilroy;
  font-style: normal;
}

.exit-title {
  font-size: 32px;
  font-weight: 700;
}

.exit-picto {
  width: 100%;
  max-width: 770px;
  margin-bottom: 40px;
  animation: float 5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.exit-text {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 400;
  a {
    text-decoration: underline;
  }
}

.exit {
  .accordion-toggle {
    background: none;
    border: none;
    font-weight: 400;
    font-size: 20px;
    color: #00556e;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
      transition: transform 300ms ease;
    }
    .rotate-icon {
      transition: transform 0.3s ease;
    }

    .rotate-icon.open {
      transform: rotate(180deg);
    }
  }
}

/* exit.scss */
.accordion-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition:
    max-height 300ms ease,
    opacity 300ms ease;

  &.open {
    max-height: 1000px;
    opacity: 1;
  }

  &.closed {
    max-height: 0;
    opacity: 0;
  }
}

@media (max-height: 800px) {
  .exit-picto {
    width: auto;
    max-height: 32vh;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .exit-title {
    font-size: 24px;
  }

  .exit-picto {
    width: 100%;
    max-height: 200px;
  }

  .exit-text {
    font-size: 16px;
  }
}
