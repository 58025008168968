.primes .diagnosticFooter {
  justify-content: flex-end;
}

// media query for desktop view min-width: 1024px
@media screen and (min-width: 1024px) {
  .primes .diagnosticQuestionList.displayColumn .diagnosticQuestionListItem {
    padding-bottom: calc((100% - 40px) / 5);
  }
}
