.dashboard {
  &__simulations {
    margin: 4rem 0;
  }
  &__actions {
    margin: 4rem 0;
  }

  &__separator {
    margin: 4rem auto;
    max-width: 200px;
    height: 3px;
    background: #00556e;
    border-radius: 10px;
  }
}

// write media queries here for all the breakpoints
@media (max-width: 768px) {
  .dashboard {
    &__actions {
      margin: 2rem 0;
      .button {
        font-size: 14px;
      }
    }
  }
}
