// MODAL AAT QUESTIONS

.aat {
  &__container {
    background-color: #f2f6f8;
    padding: 2%;
    border-radius: 10px;

    &--header {
      max-width: 990px;
      margin: auto;
    }
  }
  &__content {
    max-width: 990px;
    margin: 4rem auto;
    &--form {
      &__header {
        padding: 3rem 3rem 1rem 3rem;
        background-color: #ffffff;
        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: #00556e;
        }

        &__subtitle {
          margin: 10px 0;
          display: flex;
          align-items: center;
          gap: 1rem;

          &--text {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #f4a008;
          }
        }
      }
      &__panel {
        padding: 0rem 3rem 3rem 3rem;
        background-color: #ffffff;
        margin-bottom: 4rem;
      }
    }

    &.aat-questions {
      .diagnosticQuestionTextLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #00556e;
      }
      .diagnosticQuestionList.displayGrid {
        flex-wrap: nowrap;
      }
      .diagnosticQuestionList:not(.displayColumn) .diagnosticQuestionListItemContent {
        all: unset;
        width: 100%;
        text-align: center;
        padding: 10px;
      }

      .lower__label {
        .diagnosticQuestionTextLabel {
          font-size: 14px;
        }
      }
    }
  }
}

.aat-questions {
  .diagnostic {
    &Question {
      &Why {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        color: #00556e;
        margin-bottom: 0;
        &Title {
          display: none;
        }
        &Icon {
          font-size: 2rem;
          margin-left: 10px;
          color: #a0a0a0;
        }
        &Details {
          color: #00556e;
          font-size: 16px;
        }
      }
      &List {
        &Item {
          background: #00556e;
          color: #fff;
          min-height: 48px;
          border-radius: 10px;
          &Content {
            color: #fff;
          }
          &.selected {
            background: #f4a008;
            &::after {
              content: none;
            }
          }
          &::before {
            content: none;
          }
        }
      }
    }
  }
}

.custom_tooltip {
  color: #fff;
  padding: 8px;
  border-radius: 10px;
  font-size: 14px !important;
  font-style: italic;
}

// MODAL AAT STEPS
.aat-primes__modal {
  background-color: #f2f6f8;
  padding: 2%;
}
.aat_wrapper {
  background-color: #ffffff;
  max-width: 990px;
  margin: 4rem auto;
  padding: 3rem;
  background: #ffffff;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.aat-modal__presentation {
  &-title {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    margin-bottom: 2rem;
  }
}

.aat-primes__amount {
  max-width: 600px;
  margin: 4rem auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 20px;

  &-price {
    font-weight: 700;
    font-size: 30px;
    color: #f4a008;
    margin-bottom: 0px;
  }
  &-title {
    font-weight: 700;
    font-size: 16px;
    color: #00556e;
    margin-bottom: 0px;
  }
  &-text {
    display: none;
  }
}

.aat-primes__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #00556e;
    margin-bottom: 0;
  }
  &-download {
    margin-left: 2rem;
    background: transparent;
    border: 0;
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.aat-primes__request-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #00556e;
  white-space: pre-line;
}

.aat-primes__steps {
  margin: 4rem 0;
  position: relative;

  &-linear {
    top: 0;
    content: '';
    z-index: 0;
    position: absolute;
    width: 35px;
    height: 95%;
    background: linear-gradient(to bottom, #f4a10805 30%, #f4a10840 60%, #f4a10884 100%);
    border-radius: 35px;
  }
  &:last-child {
    padding-bottom: 0;
    &::after {
      content: none;
    }
  }

  .aat-primes__step {
    display: flex;
    align-items: flex-start;
    padding-bottom: 3rem;

    &-number {
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      margin-right: 4%;
      width: 35px;
      height: 35px;
      background: #00556e;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }
    &-title {
      flex: 1;
      line-height: normal;
      font-weight: 700;
      font-size: 18px;
      color: #00556e;
      margin-bottom: 0;
      &--info {
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
.aat-primes__actions {
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  .aat-button {
    padding: 10px 15px;
    border-radius: 10px;
    border: 0;
    min-width: 122px;
    min-height: 45px;
    font-weight: bold;
    font-size: 18px;

    &--primary {
      background: #f4a008;
      color: #fff;

      &:hover {
        background: darken(#f4a008, 10%);
      }
    }
  }
}
.aat-prime__footer {
  &-info {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    color: #00556e;
    margin-top: 2rem;
    text-align: center;
  }
}

// PDF
.aat-primes__amount--pdf {
  box-shadow: none !important;
  border: 2px solid rgba(0, 0, 0, 0.25) !important;
}
.aat-primes__pdf {
  width: 778px !important;
  height: 863px !important;
  padding: 25px 50px 30px !important;
}
.aat-primes__hideOnPdf {
  display: none !important;
}

// responsive media queries
@media (max-width: 1024px) {
  .aat-modal__title,
  .aat-primes__header-title {
    font-size: 18px;
    margin-bottom: 0;
  }
  .aat-question {
    &__container {
      .diagnosticQuestionTextLabel {
        font-size: 14px;
      }
    }
    &__actions {
      .aat-button {
        font-size: 14px;
      }
    }
    &__information {
      justify-content: center;
      .aat-button--info {
        font-size: 14px;
      }
    }
  }
  .aat-questions {
    .diagnostic {
      &Question {
        &Why {
          &Icon {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .aat-primes__steps {
    margin: 4rem 0;
    position: relative;

    .aat-primes__step {
      &-number {
        font-size: 16px;
      }
      &-title {
        font-size: 16px;

        &--info {
          font-size: 12px;
        }
      }
    }
  }
}
// tablet media queries
@media (max-width: 768px) {
  .aat-modal__title,
  .aat-primes__header-title {
    font-size: 16px;
  }
  .aat-question {
    &__container {
      .diagnosticQuestionTextLabel {
        font-size: 14px;
      }
    }
  }
  .aat {
    &__content {
      &--form {
        &__header {
          &__title {
            font-size: 18px;
          }
          &__subtitle {
            &--text {
              font-size: 14px;
            }
          }
        }
      }

      &.aat-questions {
        .diagnosticQuestionList.displayGrid {
          flex-wrap: wrap;
        }
      }
    }
  }
}

// mobile media queries
@media (max-width: 480px) {
  .aat-primes__modal {
    .modal-body {
      padding: 0 0 20px;
    }
    .modal-content {
      box-shadow: none;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  .aat-primes__amount {
    margin-top: 0;
    background: #00556e;
    border-radius: 0;
    box-shadow: none;

    &-price,
    &-title {
      color: #ffffff;
    }
    &-text {
      color: #ffffff;
      display: block;
      font-size: 12px;
    }
  }

  .aat-primes__amount,
  .aat-primes__header-title {
    text-align: center;
  }

  .aat-modal__presentation-title,
  .aat-primes__request-text,
  .aat-prime__footer-info {
    display: none;
  }

  .aat-primes__request {
    padding: 0 15px;
  }

  .aat-primes__header {
    justify-content: center;

    &-download {
      display: none;
    }
  }
}
