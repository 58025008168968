.accordion__documents {
  margin-top: 2rem;
  max-width: 600px;

  .accordion {
    .accordion-item {
      border: none;
      .accordion-header {
        button {
          background: #ffffff;
          border: 1px solid #00556e;
          border-radius: 20px;
        }
      }
      .accordion-collapse {
        background-color: #eef5f8;
        border-radius: 20px 20px 10px 10px;
        padding: 4rem 2rem;
        margin-top: -4rem;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;

    gap: 1rem;
    &__infos {
      display: flex;
      gap: 1rem;
      align-items: center;

      &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
    }
    &__file {
      display: flex;
      align-items: center;
      gap: 1rem;

      &__name {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        overflow-wrap: break-word;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

// responsive

@media (max-width: 768px) {
  .accordion__documents {
    &__header {
      width: 100%;
      gap: 0.5rem;
      &__infos {
        gap: 0.5rem;
        &__title {
          font-size: 14px;
        }
      }
      &__file {
        gap: 0.5rem;
        &__name {
          font-size: 12px;
        }
      }
    }
  }
}
