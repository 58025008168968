.list {
  &-professionnel {
    margin-top: 10px;
    background-color: #f4f4f4;
    padding: 15px 0;

    &.curved {
      border-radius: 1rem;
      max-height: 400px;
      overflow-y: auto;
    }
    &__header {
      padding: 0 1rem;
    }
    &--spinner {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: #252525;
      margin-bottom: 2rem;
      &--count {
        font-weight: bold;
      }
    }

    &__filter {
      &__text {
        cursor: pointer;
        display: flex;
        align-items: center;
        max-width: max-content;
        background: #f2f2f2;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        padding: 4px;
        outline: none;
      }
      svg {
        background: #d9d9d9;
        border-radius: 50%;
        margin: 0.2rem;
      }
    }

    &__body {
      &::-webkit-scrollbar {
        width: 0.5rem;
      }
      &::-webkit-scrollbar-track {
        background: #00556e;
        border-radius: 0.5rem;
      }
      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
      }
    }

    &__separator {
      width: 70%;
      height: 1px;
      background: #8b8b8b;
      margin: 2rem 0 2rem 0;
    }

    &__item {
      &__content {
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
        padding: 0.5rem 1rem;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &__key {
          width: 5%;
        }

        &__name {
          font-weight: bold;
          font-size: 1.4rem;
          line-height: 1.7rem;
          color: #00556e;
          text-transform: uppercase;
          width: 20%;
        }
        &__fullAddress {
          font-weight: 400;
          font-size: 1.4rem;
          color: #000000;
          width: 40%;
        }
        &__address {
          line-height: normal;
          width: 15%;
          &--codePostal {
            color: #000000;
            font-size: 1.4rem;
            font-weight: 600;
          }
          &--city {
            color: #000000;
            font-size: 1.4rem;
            font-weight: 400;
          }
        }
        &__rge {
          .tooltip-container {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          width: 20%;
          font-size: 1.2rem;
          font-weight: 400;
        }
        &:hover:not(.notAllowed) {
          background: #00556e;
          color: #ffffff;
          .list-professionnel__item__content__name,
          .list-professionnel__item__content__fullAddress,
          .list-professionnel__item__content__address,
          .list-professionnel__item__content__address--codePostal,
          .list-professionnel__item__content__address--city {
            color: #ffffff;
          }
        }
        &.notAllowed {
          cursor: not-allowed;

          .list-professionnel__item__content {
            &__key,
            &__name,
            &__fullAddress,
            &__address,
            &__address--codePostal,
            &__address--city {
              color: #8b8b8b;
            }
          }
        }
      }
    }

    &__footer {
      margin: 2rem 6%;

      &__button {
        font-weight: 700;
      }
    }
  }
}

// Responsive design
@media screen and (max-width: 1024px) {
  .list {
    &-professionnel {
      &__title {
        font-size: 1.6rem;
        line-height: 2rem;
      }
      &__item {
        &__content {
          &__name {
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
          &__fullAddress {
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
          &__address {
            &--codePostal {
              font-size: 1.2rem;
              line-height: 1.5rem;
            }
            &--city {
              font-size: 1.2rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .list {
    &-professionnel {
      &__item {
        &__content {
          flex-direction: column;
          align-items: flex-start;
          &__name {
            font-size: 1.4rem;
            line-height: 1.7rem;
            width: 100%;
          }
          &__fullAddress {
            font-size: 1.4rem;
            line-height: 1.7rem;
            width: 100%;
          }
          &__address {
            width: 100%;
            &--codePostal {
              font-size: 1.4rem;
              line-height: 1.7rem;
            }
            &--city {
              font-size: 1.4rem;
              line-height: 1.7rem;
            }
          }
          &__rge {
            width: 100%;
          }
        }
      }
      &__footer {
        margin: 2rem 0;
      }
    }
  }
}
