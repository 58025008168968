.controle-dossier {
  margin: 4rem 0;
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #00556e;
    &.sucess {
      color: #f4a008;
      margin-top: 10px;
    }
  }
  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #00556e;
  }
  &__info {
    margin: 6rem 0;
    display: flex;
    align-items: center;
    gap: 2rem;
    &__icon {
      z-index: 2;
    }

    &__content {
      width: 100%;
      max-width: 770px;
      background: #00556e;
      border-radius: 0 40px 40px 0;
      color: #ffffff;
      padding: 8px 20px 0 20px;
      padding-left: 5rem;
      margin-left: -5rem;
      font-weight: bold;
      font-size: 13px;
      p {
        margin-bottom: 0;
      }
      &__note {
        margin-top: 6px;
        background: #ffffff;
        color: #00556e;
        margin-left: -7rem;
        padding: 2px 10px 2px 7rem;
        max-width: 500px;
        border-radius: 0px 40px 0px 0px;
      }
    }
  }
  &__prime {
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    &.sucess {
      position: relative;
      margin: 6rem 0;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
      max-width: 400px;
    }
    &__icon {
      width: 25%;
      padding: 4rem;
      text-align: center;
      &__text {
        font-weight: 700;
        font-size: 14px;
        margin: 4px 0;
        color: #00556e;
      }
    }

    &__content {
      padding: 4rem;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 90%;
        background: #979797;
        transform: translateY(-50%);
      }

      &__price {
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        color: #f4a008;
      }
      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        color: #00556e;
      }
      &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #00556e;
      }

      &__img {
        position: absolute;
        right: -20px;
      }
    }
  }

  .dossier-wrapper {
    .dossier-header {
      display: flex;
      gap: 2rem;

      .dossier-header__title {
        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: #00556e;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #252525;
        }
      }
    }
  }
  .dossier-content {
    margin: 4rem 0;
    &__panel {
      background: #fcfcfc;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      &__header {
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px 10px 0px 0px;
        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: #cb2828;
          margin-bottom: 0;
        }
      }
      &__body {
        padding: 20px;
      }
    }
    &__contact {
      background: #ffffff;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      padding: 20px;
      &__title {
        h2 {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #252525;
        }
      }
      &__content {
        display: flex;
        padding: 4rem;
        gap: 4rem;
        &__box {
          display: flex;
          align-items: center;
          gap: 1rem;
          &__icon {
            width: 62px;
            height: 62px;
            background: #00556e;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              color: #ffffff;
            }
          }
          &__text {
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
          }
        }
      }
    }
  }
  &__incomplet_information {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    margin: 4rem 0;
    background: #ffffff;
    border: 1px solid #f4a008;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    border-radius: 20px;

    &.done {
      border: none;
    }
    &__title {
      font-weight: 400;
      font-size: 18px;
      color: #252525;
      &.done {
        font-weight: 700;
        font-size: 24px;
        color: #00556e;
      }
    }
  }
}

// reponsive media query
@media (max-width: 768px) {
  .controle-dossier {
    &__title {
      font-size: 24px;
    }
    &__subtitle {
      font-size: 14px;
    }
    &__info {
      flex-direction: column;
      justify-content: center;
      gap: 0;
      &__content {
        margin-left: 0;
        border-radius: 20px;
        padding: 10px 20px;
        text-align: center;
        &__note {
          margin-left: 0;
          border-radius: 20px;
          padding: 0;
          background: none;
          color: #ffffff;
          max-width: unset;
        }
      }
    }
    &__prime {
      flex-direction: column;
      &__icon {
        width: 100%;
        padding: 2rem;
        &__text {
          font-size: 16px;
        }
      }
      &__content {
        padding: 2rem;
        text-align: center;
        &::before {
          width: 100%;
          height: 1px;
          top: 0;
          transform: translate(0, 0);
        }
      }
    }

    .dossier-wrapper {
      .dossier-header {
        gap: 1rem;
        .dossier-header__title {
          h1 {
            font-size: 16px;
            line-height: normal;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }

    .dossier-content {
      &__contact {
        &__content {
          padding: 0;
          flex-direction: column;
          gap: 2rem;
          &__box {
            &__icon {
              width: 50px;
              height: 50px;
              svg {
                font-size: 18px;
              }
            }
            &__text {
              font-size: 16px;
            }
          }
        }
      }
    }
    &__incomplet_information {
      flex-direction: column;
      text-align: center;
    }
  }
}

@media screen and (max-width: 480px) {
  .controle-dossier {
    &__title {
      font-size: 20px;
      line-height: normal;
    }
    &__info {
      margin: 2rem 0;
      &__content {
        padding: 10px 20px;
        text-align: center;
        &__note {
          padding: 0;
          max-width: unset;
        }
      }
    }
    &__prime {
      &__icon {
        padding: 1rem;
        &__text {
          font-size: 16px;
        }
      }

      &__content {
        padding: 1rem;
        text-align: center;
        &__price {
          font-size: 28px;
          line-height: normal;
        }
        &__title {
          font-size: 16px;
          line-height: normal;
        }
        &__img {
          right: 0px;
        }
      }
    }
  }
}
