html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font-family: 'Gilroy', arial;
    font-size: 1.4rem;
    font-weight: 500;
    color: $cprimary;
    &.no-scroll {
        overflow: hidden;
    }
}

a {
    color: $cprimary;
    text-decoration: none;
    &:hover {
        color: $csecondary
    }
}

::placeholder {
    color: rgba(0, 85, 110, 0.3);
    font-weight: 600;
    font-size: 1.4rem;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

label {
    font-size: 1.2rem;
    display: block;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"] {
    background: $cbg;
    color: $cprimary;
    height: 60px;
    width: 100%;
    border: 0;
    padding: 0 30px;
    font-weight: 600;
    font-size: 1.4rem;
    &.withError {
        border: 2px solid $cerror;
    }
}

textarea {
    @extend input, [type="text"];
    padding: 20px 30px;
    height: 180px;
}

.link-underline a {
    text-decoration: underline !important;
}

.alert-success {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 20px 0;
    color: $csuccess;
    font-size: 1.4rem;
    font-weight: bold;
    &:before {
        content: "\e929";
        font-family: 'Icomoon' !important;
        display: inline-block;
        vertical-align: middle;
        margin-right: 13px;
        font-size: 2.4rem;
        font-weight: normal;
    }
}
