.dashboard {
  &__demandes {
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #00556e;
      margin: 2rem 0;
    }
    &__list {
      &__item {
        &--wrapper {
          display: flex;
          justify-content: space-between;
          gap: 3rem;
        }
        &__rappel {
          text-align: center;
          font-size: 12px;
          &::before {
            content: 'i';
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            color: #ad0e0e;
            border: 1px solid #ad0e0e;
            margin-right: 10px;
            position: relative;
            font-size: 12px;
            text-align: center;
            font-weight: 900;
          }
        }

        background: #ffffff;
        border-radius: 20px;
        padding: 20px;
        margin: 2rem 0;
        &.closed {
          background: rgba(0, 0, 0, 0.2);
        }
        &__image {
          img {
            background-color: white;
            border-radius: 50%;
            height: 150px;
            width: 150px;
          }
        }
        &__content {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          flex: 1;
          &__infos {
            &--title {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: #00556e;
              margin-bottom: 1rem;
              &__icon {
                margin-right: 1rem;
              }
            }
            &--box {
              display: flex;
              align-items: center;
              font-family: 'Gilroy';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: #00556e;
              padding-bottom: 1rem;
              svg {
                margin-right: 1rem;
              }
            }
            &--actions {
              margin-top: 4rem;
              &--dateLimite {
                font-style: italic;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #f4a008;
              }
              &--condition {
                display: flex;
                &--date {
                  margin-left: 2rem;
                  &--texte {
                    margin-left: 2rem;
                    font-style: italic;
                    &--detail {
                      margin-bottom: 2rem;
                      font-style: italic;
                    }
                  }
                }
              }
            }
          }
        }
        &__prime {
          flex: 0.5;
          &__amount {
            background: #00556e;
            border-radius: 10px;
            color: #ffffff;
            font-style: normal;
            font-weight: 700;
            padding: 15px;
            &-text {
              font-size: 15px;
              margin-bottom: 0;
            }
            &-subtext {
              font-size: 14px;
              margin-bottom: 0;
              font-weight: 400;
              text-align: left;
            }
            &-value {
              font-size: 24px;
              margin-bottom: 0;
            }
            &-title {
              text-align: left;
              font-weight: bold;
              font-size: large;
            }
            &.grised {
              background: #979797;
            }
            &.left {
              text-align: left;
            }
            &.details {
              text-align: left;

              .dashboard__demandes__list__item__prime__amount-value {
                font-size: 40px;
              }
              .dashboard__demandes__list__item__prime__amount-value-titre {
                text-align: center;
                font-weight: bold;
                font-size: large;
              }
            }
            .montant-item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 1rem;

              .montant-label {
                font-weight: 600;
              }

              .montant-value {
                font-weight: 400;
              }

              &.montant-resteacharge .montant-value,
              &.montant-resteacharge .montant-label {
                font-size: 16px;
                font-weight: 900;
              }
            }
          }
          &__actions {
            margin-top: 1rem;
            &--group {
              margin-top: 1rem;
              display: flex;
              justify-content: center;
              svg {
                width: 18px;
              }
            }
          }
        }
      }
    }
  }
  &__note {
    margin: 2rem 0;
    &__text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #08556d;
    }
  }
}

.motif__Overlay {
  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    color: #252525;
  }

  &__actions {
    margin: 4rem 0;
  }

  &__choices {
    margin: 2rem 0;
    &__choice {
      display: flex;
      gap: 1rem;
      margin: 1rem 0;
      label {
        font-size: 16px;
        line-height: 20px;
        color: #000000;
      }
      input[type='checkbox'] {
        width: 20px;
        // change the appearance of the checkbox
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid #00556e;
        border-radius: 5px;
        width: 20px;

        // create the checkmark
        &:checked {
          background-color: #00556e;
          border: 1px solid #00556e;

          &::after {
            content: '';
            display: block;
            width: 8px;
            height: 13px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
            margin: 0 auto;
          }
        }
      }
    }
  }

  &__disclaimer {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #252525;
  }
}

@media (max-width: 1440px) {
  .dashboard {
    &__demandes {
      &__list {
        &__item {
          &__prime {
            flex: 0.8;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .dashboard {
    &__demandes {
      &__list {
        &__item {
          &__prime {
            flex: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard {
    &__demandes {
      &__title {
        font-size: 20px;
      }
      &__list {
        &__item {
          &--wrapper {
            flex-direction: column;
            gap: 1rem;
          }
          &__image {
            img {
              width: 100%;
            }
          }
          &__content {
            flex-direction: row;
            margin: 10px 0;
            gap: 1rem;
            &__infos {
              &--title {
                font-size: 14px;
              }
              &--box {
                font-size: 14px;
              }
              &--actions {
                margin-top: 2rem;
              }
            }
          }
          &__prime {
            flex-direction: column;
            gap: 1rem;
            &__amount {
              padding: 8px;
              text-align: left;
              &.details {
                text-align: center;
              }
              &-text {
                font-size: 12px;
              }
              &-value {
                font-size: 20px;
              }
            }
            &__actions {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}
