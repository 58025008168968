.navigation-item {
  width: 100%;
  button {
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    .spinner-grow {
      margin-left: 5px;
      color: #00556e !important;
    }
  }

  &__label {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    background: #f2f6f8;
    border-radius: 10px;
    font-weight: 700;
    padding: 6px 20px;
    margin: 0.5rem 0;
    display: block;
    text-align: left;
    &--active {
      background: #00556e;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
    }
  }

  &__sub-navigation {
    padding: 1rem 0;
    margin-left: 1.2rem;
    display: flex;
    flex-direction: column;

    &__item {
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      color: #252525;
      padding: 0.5rem 0;

      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        background: #00556e;
        border-radius: 50%;
        margin-right: 10px;
      }
      &.active {
        transition: all 0.3s ease-in-out;
        color: #f4a008;
        &::before {
          background: #f4a008;
        }
      }
    }
  }
}

// reponsive design
@media (max-width: 1400px) {
  .navigation-item {
    &__label {
      font-size: 14px;
    }
  }
}
