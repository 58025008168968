$modal-blur: 5px;

.lineBreak {
  white-space: pre-wrap;
  text-align: left !important;
}
div.modal {
  &:has(div.blurModalBackground) {
    backdrop-filter: blur($modal-blur);
    text-align: center;
  }
}
