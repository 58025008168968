$cprimary: #00556E;
$csecondary: #F4A008;
$csecondaryDarker: #e69707;
$ctertiary: #009B97;
$cvalidate: #23C275;
$cvalidate2: #25C379;
$csuccess: #349989;
$cbg: #F5F8F9;
$cbgDarker: #eef1f2;
$cwhite: #FFFFFF;
$cerror: #EB1F1F;
$calert: #C52B47;
$clevel1: #EF1713;
$clevel2: #FF5403;
$clevel3: #FF7800;
$clevel4: #FFA400;
$clevel5: #FFCA03;
$clevel6: #CFD30E;
$clevel7: #A9CC26;
$clevel8: #73C83A;
$clevel9: #08AA08;
$clevel10: #098E3B;
$cbad: #c52b47;
$cgood: #51B155;
$clettera: #019C6D;
$cletterb: #51B155;
$cletterc: #77BD75;
$cletterd: #EFD954;
$clettere: #F0B50F;
$cletterf: #EC8236;
$cletterg: #D7221F;
$cborder: #E0E0E0;