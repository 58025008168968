.contact {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 20px;
  margin-top: 4rem;
  color: #000000;
  font-size: 1.5rem;
  &__image {
    width: 100%;
    height: 110px;
    object-fit: cover;
    margin-bottom: 1rem;
    box-sizing: border-box;
    border: 1px solid #d0d0d0;
    border-radius: 10px;
  }
  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #000000;
  }
  &__email {
    text-decoration: underline;
  }
}
