.not-found {
  &__container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    height: calc(100vh - 70px);
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    flex-direction: column;
    img {
      animation: space-float 5s infinite;
    }
  }
}

.dashboard {
  .not-found {
    &__container {
      height: calc(100vh - 100px);
    }
  }
}

// media queries
@media (max-width: 1500px) {
  .not-found {
    &__container {
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 760px) {
  .not-found {
    &__container {
      height: calc(100vh - 55px);
    }
  }
  .dashboard {
    .not-found {
      &__container {
        height: calc(100vh - 85px);
      }
    }
  }
}

@keyframes space-float {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}
