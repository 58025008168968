.account {
  &Login {
    display: flex;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100vh - 70px);
    z-index: 10;
    &Close {
      display: none;
    }
    &Card {
      background: $cwhite;
      position: relative;
      z-index: 11;
      min-height: 100%;
      width: 410px;
      z-index: 2;
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
      &Title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 50px;
        text-align: center;
      }
      &ForgetPassword {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
        margin-top: -15px;
        .btn-link {
          padding: 0;
          text-decoration: underline;
        }
      }
      .btn-primary {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &Register {
        text-align: center;
        margin-top: 40px;
        .btn {
          font-size: 1.4rem;
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
    &Overlay {
      background: rgba(0, 0, 0, 0.7);
      width: calc(100% - 410px);
      height: 100%;
      z-index: 1;
      position: relative;
      border: 0;
    }
  }
  &Menu {
    &Container {
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: calc(100vh - 70px);
      z-index: 10;
      position: fixed;
      top: 70px;
      left: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      border: 0;
      padding: 0;
    }
    &List {
      background: $cwhite;
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
      z-index: 11;
      &Item {
        height: 71px;
        &Link {
          height: 100%;
          width: 100%;
          padding: 0 25px;
          color: $cprimary;
          display: flex;
          align-items: center;
          &:hover {
            background: rgba(0, 85, 110, 0.1);
            color: $cprimary;
          }
        }
        &Icon {
          margin-right: 20px;
          i {
            font-size: 2.1rem;
            &.icon-roof {
              font-size: 2.5rem;
            }
          }
        }
      }
    }
  }
  &Survey {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    min-height: calc(100vh - 70px);
    background: $cbg;
    &Title {
      font-weight: bold;
      font-size: 3.2rem;
      font-weight: 500;
      margin-bottom: 40px;
      &.forMobile {
        display: none;
      }
      strong {
        font-weight: 700;
      }
      &Sub {
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 15px;
      }
    }
    &Infos {
      font-size: 1.4rem;
      line-height: 2.2rem;
      margin-bottom: 35px;
    }
    &Left {
      padding: 30px 0;
      .accountSurvey {
        &TitleSub,
        &Infos {
          &.forMobile {
            display: none;
          }
        }
      }
      &None {
        background: $cwhite;
        @include border-radius(10px);
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
        margin-right: calc(9vw - 20px);
        &Illu {
          margin-bottom: 20px;
          height: 95px;
          img {
            max-height: 100%;
          }
        }
      }
    }
    &Right {
      min-width: 510px;
      width: 510px;
      background: $cprimary;
      color: $cwhite;
      padding: 54px 50px;
      &Item {
        &Container {
          display: flex;
          flex-wrap: wrap;
        }
        position: relative;
        background: $cwhite;
        color: $cprimary;
        width: calc(50% - 10px);
        margin-bottom: 20px;
        padding: 24px 10px 20px;
        @include border-radius(10px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
        &:nth-child(2n + 1) {
          margin-right: 20px;
        }
        &.active {
          background: $csecondary;
          color: $cwhite;
          .accountSurveyRightItemPlus {
            background: $cwhite;
            color: $csecondary;
            i {
              font-size: 1.2rem;
            }
          }
        }
        &Label {
          font-size: 1.4rem;
          line-height: 1.6rem;
          margin-bottom: 10px;
          font-weight: 700;
          text-align: center;
          max-width: 195px;
        }
        &Illu {
          img {
            max-width: 100%;
            height: 130px;
          }
        }
        &Plus {
          width: 35px;
          height: 35px;
          @include border-radius(50%);
          display: flex;
          align-items: center;
          justify-content: center;
          background: $csecondary;
          color: $cwhite;
          position: absolute;
          bottom: 10px;
          right: 10px;
          padding: 0;
          i {
            font-size: 1.5rem;
          }
        }
      }
    }
    &List {
      display: flex;
      flex-wrap: wrap;
      .slick-slide {
        max-width: 300px;
        & > div {
          margin: 0 10px;
        }
      }
      .slick-list {
        margin: 0 -10px;
        width: 100%;
        padding: 20px 0;
      }
      .slick-track {
        margin: 0;
      }
      &Card {
        background: $cwhite;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
        @include border-radius(10px);
        &.complete {
          .accountSurveyListCard {
            &HeaderStatus {
              background: $cvalidate;
            }
            &Body {
              &Status {
                color: $cvalidate;
              }
              .progress-bar {
                background: $cvalidate;
              }
            }
          }
        }
        &Header {
          padding: 10px 15px;
          border-bottom: 1px solid rgba(0, 85, 110, 0.1);
          display: flex;
          align-items: center;
          justify-content: space-between;
          &Date {
            color: $ctertiary;
            font-size: 1.3rem;
            line-height: 1.4rem;
            font-weight: 600;
            margin-right: 20px;
            display: flex;
            align-items: center;
            i,
            svg {
              margin-right: 8px;
              font-size: 1.6rem;
            }
          }
          &Status {
            background: $cprimary;
            color: $cwhite;
            @include border-radius(17px);
            padding: 7px 13px;
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 1.2rem;
            line-height: 1.3rem;
            i,
            svg {
              margin-right: 7px;
              font-size: 1.6rem;
            }
          }
        }
        &Body {
          padding: 25px 22px;
          text-align: center;
          .progress {
            height: 8px;
            &-bar {
              background: linear-gradient(270deg, #009b97 0%, #00556e 100%);
            }
          }
          .btn-link {
            margin-top: 20px;
          }
          &Title {
            font-weight: 700;
            font-size: 2rem;
            line-height: 3.2rem;
            margin-bottom: 5px;
          }
          &User {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
            &Label {
              font-size: 1.3rem;
              line-height: 1.5rem;
              display: flex;
              align-items: center;
            }
            &Icon {
              font-size: 1.25rem;
              color: $cprimary;
              width: 26px;
              height: 26px;
              @include border-radius(50%);
              margin-right: 9px;
              background: rgba(0, 85, 110, 0.1);
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          &Status {
            font-size: 1.6rem;
            line-height: 3.2rem;
            margin-top: 4px;
            margin-bottom: 30px;
            color: $cprimary;
          }
        }
      }
    }
  }
  &Infos {
    display: flex;
    &Content {
      width: calc(100% - 330px);
      padding: 40px 100px;
      form {
        max-width: 780px;
        button[type='submit'] {
          margin-top: 16px;
        }
      }
    }
    &Menu {
      background: $cprimary;
      min-height: calc(100vh - 70px);
      width: 330px;
      padding: 40px 0;
      &List {
        list-style: none;
        padding: 0;
        margin: 0;
        &Item {
          &Link {
            color: $cwhite;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            padding: 15px 30px;
            width: 100%;
            &.active {
              color: $csecondary;
              font-weight: 700;
            }
          }
          &Icon {
            width: 24px;
            margin-right: 20px;
            i {
              font-size: 2.4rem;
            }
          }
        }
      }
      .accordion {
        &-item {
          border: 0;
        }
        &-body {
          padding: 30px;
        }
        &-button {
          height: 70px;
          background: $cprimary;
          color: $cwhite;
          padding: 0 30px;
          font-size: 1.4rem;
          &:focus {
            box-shadow: none;
          }
          &:not(.collapsed) {
            color: $csecondary;
            font-weight: 700;
            &:after {
              content: '\e942';
              font-size: 0.2rem;
            }
          }
          &:after {
            content: '\e916';
            font-family: 'icomoon' !important;
            font-size: 1.2rem;
            position: absolute;
            right: 30px;
            top: 50%;
            @include transform(translateY(-50%));
            background: none;
            width: auto;
            height: auto;
          }
        }
      }
    }
    &Title {
      font-size: 3.2rem;
      font-weight: 500;
      margin: 0;
      &Sub {
        font-size: 2rem;
        font-weight: 700;
        margin: 30px 0;
      }
    }
    &Date {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      i,
      svg {
        color: $ctertiary;
        margin-right: 5px;
        font-size: 1.6rem;
      }
    }
    &Delete {
      &Link {
        background: none;
        border: 0;
        color: $cprimary;
        text-decoration: underline;
        display: inline-block;
        font-weight: bold;
        &:hover {
          color: $csecondary;
        }
      }
    }
    &Password {
      &Title {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 25px;
      }
      &Details {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-bottom: 15px;
      }
      &Strength {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        &Item {
          color: rgba(0, 85, 110, 0.3);
          font-size: 1.2rem;
          line-height: 1.8rem;
          &.active {
            &.weak {
              color: $cerror;
              font-weight: bold;
              &:before {
                background: $cerror;
              }
            }
            &.medium {
              color: $csecondary;
              font-weight: bold;
              &:before {
                background: $csecondary;
              }
            }
            &.strong {
              color: $cvalidate2;
              font-weight: bold;
              &:before {
                background: $cvalidate2;
              }
            }
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
          &:before {
            content: '';
            width: 80px;
            height: 4px;
            background: rgba(0, 85, 110, 0.1);
            margin-bottom: 6px;
            display: block;
            @include border-radius(2.5px);
          }
        }
      }
      &Required {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 445px;
        margin-bottom: 14px;
        &Item {
          width: 50%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          &.withError {
            color: $cerror;
          }
          &.disabled {
            opacity: 0.3;
          }
          &Icon {
            font-size: 2.1rem;
            line-height: 2.1rem;
            width: 21px;
            margin-right: 15px;
          }
          &Label {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  &ForgetPassword {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &Container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 70px);
      padding: 30px;
    }
    &Form {
      width: 100%;
      max-width: 780px;
      .btn-primary {
        margin-top: 25px;
      }
    }
    &Picto {
      width: 50px;
      height: 50px;
      font-size: 5rem;
      margin-bottom: 30px;
      color: $ctertiary;
    }
    &Title {
      font-size: 3.2rem;
      line-height: 3.2rem;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: center;
    }
    &Infos {
      font-size: 1.4rem;
      line-height: 2.2rem;
      text-align: center;
      margin-bottom: 60px;
    }
  }
}
