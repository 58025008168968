.diagnostic {
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 70px);
  padding-bottom: 80px;
  &Anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }
  &Chapter {
    background: $cbg;
    padding: 50px;
    min-width: 310px;
    position: sticky;
    top: 70px;
    align-self: flex-start;
    height: calc(100vh - 150px);
    overflow-y: auto;
    &Prev {
      display: none;
    }
    &List {
      list-style: none;
      font-size: 1.4rem;
      padding: 0;
      margin: 0;
      &Item {
        color: $ctertiary;
        opacity: 0.5;
        margin-bottom: 35px;
        &.primes,
        &.complete {
          width: 50px;
          margin-left: 5px;
          margin-right: 5px;
        }
        &Label {
          display: flex;
          align-items: center;
          &Icon {
            background: $ctertiary;
            width: 20px;
            height: 20px;
            @include border-radius(50%);
            color: $cwhite;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            font-size: 0.7rem;
          }
        }
        .progress {
          margin-top: 10px;
        }
        &.active {
          opacity: 1;
          font-weight: bold;
          &.primes {
            flex: 1;
          }
        }
      }
      &Mobile {
        display: none;
      }
    }
  }
  &Question {
    padding: 40px 100px 65px;
    min-width: calc(100% - 310px);
    .diagnosticQuestion {
      padding: 0;
    }
    &.coordinate {
      padding: 0 80px 0 0;
      & > .diagnosticQuestionLabel {
        display: none;
        & + .diagnosticQuestionWhy {
          display: none;
        }
      }
    }
    &Label {
      font-size: 3.2rem;
      line-height: 3.2rem;
      font-weight: bold;
      margin-bottom: 25px;
      span {
        font-weight: 500;
      }
      &More {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-top: -10px;
        margin-bottom: 16px;
      }
      &Wrapper {
        .icon-info {
          display: none;
        }
      }
    }
    &Cat {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &Title {
        font-size: 2rem;
        font-weight: 700;
      }
      &Link {
        color: $ctertiary;
        display: flex;
        align-items: center;
        display: none;
        i {
          font-size: 1.6rem;
        }
        &Label {
          margin-left: 5px;
          text-decoration: underline;
          font-size: 1.3rem;
        }
      }
    }
    &Coordinate {
      position: absolute;
      background: $cwhite;
      z-index: 1;
      width: 60px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 700;
      &Container {
        display: flex;
        position: relative;
        & > div {
          flex: 1;
        }
      }
      &Right {
        margin-left: 80px;
        padding: 30px 0;
      }
      &Map {
        position: sticky;
        bottom: 80px;
        align-self: flex-end;
        &Element {
          height: calc(100vh - 150px);
          z-index: 0;
        }
      }
      i {
        font-size: 1.4rem;
        display: inline-block;
      }
      span {
        margin-left: 5px;
        margin-right: 5px;
        text-align: center;
        width: 100%;
      }
      &.north {
        top: 0;
        left: 50%;
        flex-direction: column;
        @include transform(translateX(-50%));
        @include border-radius(0 0 10px 10px);
        i {
          color: $cbad;
        }
        span {
          margin-top: 3px;
        }
      }
      &.north-east {
        top: 0;
        left: 100%;
        width: 100px;
        @include transform(translateX(-100%));
        @include border-radius(0 0 0 10px);
        i {
          align-self: flex-start;
          @include transform(rotate(45deg));
        }
        span {
          align-self: flex-end;
          margin-bottom: 10px;
          margin-left: 10px;
        }
      }
      &.north-west {
        top: 0;
        left: 0;
        width: 100px;
        @include border-radius(0 0 10px 0);
        i {
          align-self: flex-start;
          @include transform(rotate(-45deg));
        }
        span {
          align-self: flex-end;
          margin-bottom: 10px;
          margin-right: 10px;
        }
      }
      &.south {
        bottom: 0;
        left: 50%;
        flex-direction: column;
        @include transform(translateX(-50%));
        @include border-radius(10px 10px 0 0);
        i {
          @include transform(rotate(180deg));
        }
        span {
          margin-bottom: 3px;
          margin-top: 5px;
        }
      }
      &.south-east {
        bottom: 0;
        left: 100%;
        width: 100px;
        @include transform(translateX(-100%));
        @include border-radius(10px 0 0 0);
        i {
          align-self: flex-end;
          @include transform(rotate(135deg));
        }
        span {
          align-self: flex-start;
          margin-top: 13px;
          margin-left: 10px;
        }
      }
      &.south-west {
        bottom: 0;
        left: 0;
        width: 100px;
        @include border-radius(0 10px 0 0);
        i {
          align-self: flex-end;
          @include transform(rotate(-135deg));
        }
        span {
          align-self: flex-start;
          margin-top: 13px;
          margin-right: 10px;
        }
      }
      &.east {
        right: 0;
        top: 50%;
        justify-content: flex-end;
        span {
          margin-right: 5px;
        }
        @include transform(translateY(-50%));
        @include border-radius(10px 0 0 10px);
        i {
          @include transform(rotate(90deg));
        }
      }
      &.west {
        left: 0;
        top: 50%;
        padding-right: 5px;
        @include transform(translateY(-50%));
        @include border-radius(0 10px 10px 0);
        i {
          @include transform(rotate(-90deg));
        }
      }
    }
    &Why {
      display: flex;
      color: $csecondary;
      margin-bottom: 35px;
      &Title {
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: bold;
        margin-bottom: 0;
      }
      &Details {
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 0;
        a {
          color: $csecondary;
          font-weight: bold;
          text-decoration: underline;
        }
      }
      &Icon {
        font-size: 3.5rem;
        line-height: normal;
        margin-right: 15px;
      }
    }
    &Help {
      margin-bottom: 20px;
      &Details {
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        color: #00556e;
      }
    }
    &Select {
      &.form-select {
        height: 60px;
        border: 0;
        @include border-radius(0);
        background-color: $cbg;
        background-position: right 20px center;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23009B97' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        color: $cprimary;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 25px;
        padding: 0 30px;
      }
      &Container {
        position: relative;
        .diagnosticQuestionTextLabel {
          padding-right: 30px;
        }
      }
      &Infos {
        background: none;
        padding: 0;
        border: 0;
        color: $csecondary;
        font-size: 1.8rem;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    &Text {
      position: relative;
      div[class$='-Input'] {
        color: $cprimary;
        font-weight: 600;
      }
      &Label {
        font-size: 1.2rem;
        margin-bottom: 10px;
        display: block;
        color: $cprimary;
      }
      &Input {
        background: $cbg;
        height: 60px;
        font-size: 1.4rem;
        font-weight: 600;
        color: $cprimary;
        padding: 0 30px;
        border: 0;
        width: 100%;
        &.withUnit {
          padding: 0 70px 0 30px;
        }
        &.increment {
          text-align: center;
          font-weight: 700;
          font-size: 1.8rem;
          padding: 0 10px;
        }
        &Btn {
          background: $cbg;
          width: 60px;
          min-width: 60px;
          border: 0;
          font-size: 2.4rem;
          font-weight: 500;
          color: $cprimary;
          &:first-of-type {
            border-right: 1px solid rgba(0, 85, 110, 0.1);
          }
          &:last-of-type {
            border-left: 1px solid rgba(0, 85, 110, 0.1);
          }
        }
      }
      &Unit {
        position: absolute;
        z-index: 1;
        font-size: 1.4rem;
        font-weight: 600;
        right: 30px;
        bottom: 19px;
      }
      &Icon {
        position: absolute;
        z-index: 1;
        font-size: 3.5rem;
        right: 10px;
        bottom: 0px;
        color: $ctertiary;
        line-height: 7.5rem;
      }
    }
    &Modal {
      .modal-dialog {
        max-width: 1010px;
      }
      &List {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &Item {
          margin: 10px;
          &Picto {
            width: 180px;
            min-width: 180px;
            height: 180px;
            background: $cbg;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              height: 130px;
            }
          }
          &Label {
            margin-top: 15px;
            font-weight: 700;
            font-size: 1.4rem;
            text-align: center;
          }
        }
      }
    }
    &List {
      list-style: none;
      padding: 0;
      margin: 0;
      &.multiple {
        .diagnosticQuestionListItem {
          &:before {
            @include border-radius(0%);
          }
        }
      }
      &.displayColumn {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .diagnosticQuestionListItem {
          width: calc((100% - 40px) / 3);
          padding-bottom: calc((100% - 40px) / 3);
          font-size: 1.6rem;
          line-height: 1.8rem;
          text-align: center;
          &Content {
            padding: 40px 35px 20px;
            flex-direction: column-reverse;
            position: absolute;
            left: 0;
            top: 0;
            overflow: hidden;
          }
          &Picto {
            max-width: 100%;
            height: 80%;
            margin-left: 0;
            margin-bottom: 10px;
            img {
              height: 100%;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:before,
          &.selected:after {
            left: inherit;
            right: 10px;
            top: 10px;
            transform: none;
          }
          &.justText {
            .diagnosticQuestionListItemContent {
              justify-content: center;
            }
          }
        }
      }
      &.displayGrid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        .diagnosticQuestionListItem {
          width: calc((100% - 20px) / 2);
        }
      }
      &Children {
        background: $cbg;
        padding: 25px 70px;
        margin-top: -10px;
        margin-bottom: 10px;
        border-top: 1px solid $cborder;
        & > .diagnosticQuestionTextLabel {
          font-size: 1.4rem;
          line-height: 2rem;
        }
        .diagnosticQuestion {
          &StartPage {
            padding: 0;
          }
          &List {
            &.inline {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              .diagnosticQuestionListItem {
                width: auto;
              }
            }
            &Item {
              min-height: auto;
              border: 0;
              &:before {
                left: 0;
              }
              &:hover {
                border: 0;
              }
              &.selected:after {
                left: 0;
              }
              &Content {
                padding-left: 40px !important;
              }
            }
          }
          &Text {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
            &Input {
              background: $cwhite;
            }
          }
        }
      }
      &Infos {
        font-size: 1.3rem;
        margin-bottom: 12px;
      }
      &Item {
        background: $cbg;
        min-height: 65px;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: 600;
        border: 2px solid transparent;
        display: flex;
        align-items: center;
        cursor: pointer;
        &.increment {
          cursor: inherit;
          min-height: 275px;
          &:before {
            display: none;
          }
          &:hover {
            border-color: transparent;
          }
          .diagnosticQuestionListItem {
            &Content {
              justify-content: center;
              height: calc(100% - 60px);
            }
            &Picto {
              height: auto;
              margin-bottom: 1vw;
              i {
                font-size: 6vw;
                color: $ctertiary;
              }
            }
            &Increment {
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: 1;
              width: 100%;
              display: flex;
              border-top: 1px solid rgba(0, 85, 110, 0.1);
            }
          }
        }
        &Occupant {
          display: flex;
          justify-content: center;
          align-items: center;
          &Increment {
            display: flex;
            width: 280px;
            min-width: 280px;
            margin: 0 10px;
          }
          .diagnosticQuestionListItem {
            &Increment {
              display: flex;
            }
            width: 280px;
            min-width: 280px;
            padding: 0;
            margin: 0 10px;
          }
        }
        &Content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 100%;
          padding: 10px 30px 10px 70px;
          color: $cprimary;
          line-height: 2rem;
        }
        &Picto {
          min-width: 35px;
          height: 33px;
          margin-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-height: 100%;
          }
        }
        &:hover {
          border-color: $cprimary;
        }
        &.selected {
          border-color: transparent;
          &:before {
            background: $cprimary;
          }
          &:after {
            content: '\e900';
            font-family: 'icomoon' !important;
            color: $cwhite;
            position: absolute;
            font-size: 0.8rem;
            top: 50%;
            @include transform(translateY(-50%));
            left: 20px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &:before {
          content: '';
          width: 25px;
          height: 25px;
          background: $cwhite;
          border: 1.5px solid $cprimary;
          position: absolute;
          top: 50%;
          @include transform(translateY(-50%));
          left: 20px;
          @include border-radius(50%);
          pointer-events: none;
        }
      }
    }
    &Start {
      div[class^='col'] {
        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 50px;
        }
      }
      &Intro {
        margin-bottom: 60px;
        &Illu {
          text-align: right;
        }
        &Text {
          &Title {
            font-size: 3.2rem;
            line-height: 3.2rem;
            font-weight: bold;
            margin-bottom: 30px;
            &Sub {
              font-size: 1.4rem;
              line-height: 2.4rem;
            }
          }
        }
      }
      &Item {
        display: flex;
        &Icon {
          min-width: 30px;
        }
        &Content {
          margin-left: 20px;
          &Title {
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: bold;
          }
          &Details {
            font-size: 1.4rem;
            line-height: 2.4rem;
          }
          ul {
            padding-left: 13px;
          }
          a {
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
      &Inducement {
        text-align: center;
        font-size: 3.2rem;
        line-height: 3.2rem;
        font-weight: bold;
        margin-top: 50px;
      }
    }
  }
  &Rejected {
    display: flex;
    padding: 50px 10vw;
    &Icon {
      padding-top: 10vw;
      padding-right: 8vw;
      img {
        width: 22.2vw;
      }
    }
    &Content {
      &Title {
        font-size: 3.2rem;
        line-height: 3.6rem;
        font-weight: 700;
        margin-bottom: 20px;
        &Sub {
          font-size: 1.6rem;
          line-height: 2rem;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
      &Phone {
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: 700;
        color: $ctertiary;
        span {
          font-size: 1.4rem;
          line-height: 2rem;
          font-weight: normal;
          color: $cprimary;
          display: block;
        }
      }
      &Intro {
        border-top: 1px solid rgba(0, 85, 110, 0.1);
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 30px;
        margin-top: 30px;
        padding-top: 30px;
      }
      &Form {
        &Checkbox {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          .customCheckbox {
            margin-bottom: 0;
          }
          &Label {
            font-size: 1.4rem;
            margin-right: 20px;
          }
        }
        &Mentions {
          font-size: 1.2rem;
          margin-bottom: 30px;
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &Footer,
  .startPage {
    justify-content: flex-end;
  }
  &Footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background: $cprimary;
    z-index: 2;
    &Cgu {
      color: $cwhite;
      margin-right: 80px;
      a {
        color: $cwhite;
        text-decoration: underline;
      }
    }
    .btn-primary {
      &.mobile {
        display: none;
      }
    }
    &Save {
      color: $cwhite;
      display: flex;
      align-items: center;
      margin-left: 20px;
      background: none;
      border: 0;
      text-align: left;
      padding: 0;
      &:hover .diagnosticFooterSaveLabel {
        text-decoration: none;
      }
      &Label {
        font-size: 1.6rem;
        line-height: 1.8rem;
        max-width: 145px;
        text-decoration: underline;
      }
      i {
        color: $csecondary;
        font-size: 2.5rem;
        margin-right: 15px;
      }
    }
    &Actions {
      display: flex;
      align-items: center;
      .btn-icon-left {
        margin-right: 10px;
      }
    }
  }
}

.diagnosticResult {
  padding-bottom: 100px;
  &Banner {
    background: $cprimary;
    color: $cwhite;
    min-height: 280px;
    position: relative;
    padding: 35px 0 50px;
    &Container {
      display: flex;
      align-items: center;
    }
    &Illu {
      width: 260px;
      min-width: 260px;
      margin-right: 60px;
      img {
        width: 100%;
      }
    }
    &Infos {
      &Title {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.2rem;
        margin-bottom: 20px;
      }
      &Anchors {
        &Container {
          display: flex;
          z-index: 11;
        }
        &.sticky {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          background: $cwhite;
          box-shadow: 40px 0 0 0 rgba(0, 0, 0, 0.05);
          padding: 10px 0;
          z-index: 10;
          .diagnosticResultBannerInfosAnchors {
            &Container {
              max-width: 1320px;
              margin: 0 auto;
            }
            &Label {
              display: none;
            }
            &List {
              &Item {
                &Link {
                  background: $cbg;
                }
              }
            }
          }
        }
        &Label {
          font-size: 1.2rem;
          margin-bottom: 12px;
        }
        &Right {
          margin-left: 55px;
        }
        &Left {
          min-width: max-content;
        }
        &List {
          display: flex;
          align-items: center;
          padding: 0;
          margin: 0;
          list-style: none;
          &Item {
            &:not(:last-child) {
              margin-right: 10px;
            }
            &Link {
              background: $cwhite;
              color: $cprimary;
              font-weight: 700;
              padding: 14px 20px;
              @include border-radius(10px);
              display: inline-block;
              min-width: max-content;
            }
          }
        }
      }
    }
    &Date {
      position: absolute;
      right: 65px;
      bottom: 15px;
      font-size: 1.1rem;
    }
  }
  &Title {
    font-weight: 700;
    font-size: 3.6rem;
    margin-bottom: 40px;
    small {
      font-size: 0.5em;
      font-weight: normal;
    }
  }
  &Intro {
    font-size: 1.8rem;
    line-height: 2.6rem;
    font-weight: 700;
    margin-bottom: 60px;
    &Container {
      display: flex;
    }
    &Smiley {
      font-size: 3.6rem;
      margin-right: 20px;
    }
  }
  &Bloc {
    &:not(#analyse) {
      border: 6px solid $cprimary;
    }
    .diagnosticResult {
      &Title {
        margin-bottom: 20px;
        margin-top: 20px;
      }
      &Intro {
        max-width: 60%;
        margin-bottom: 35px;
      }
    }
    & + .diagnosticResultTitle {
      margin-top: 95px;
    }
    &Anchor {
      position: relative;
      top: -100px;
      visibility: hidden;
    }
    @include border-radius(10px);
    background: $cbg;
    margin-bottom: 50px;
    &Label {
      font-size: 1.4rem;
      line-height: 2.2rem;
      display: flex;
      align-items: center;
    }
    &Price {
      font-weight: 700;
      font-size: 2.4rem;
    }
    &Toggle {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 85, 110, 0.05);
      border: 0;
      color: $cprimary;
      font-weight: 700;
      span {
        text-decoration: underline;
      }
      i {
        margin-left: 8px;
        font-size: 0.7rem;
      }
    }
    &Body {
      padding: 35px 60px;
      &Title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0;
        &Container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 40px;
        }
        &Link {
          color: $ctertiary;
          font-weight: 700;
          text-decoration: underline;
          background: none;
          border: 0;
          padding: 0;
        }
      }
      &Cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 60px 0 40px;

        &Title {
          font-size: 2.4rem;
          font-weight: 700;
          margin-bottom: 30px;
          text-align: center;
        }
      }
      &Footer {
        display: flex;
        flex-wrap: wrap;
        font-weight: 700;
        font-size: 1.6rem;
        padding-top: 25px;
        border-top: 1px solid rgba(0, 85, 110, 0.2);
        &Link {
          text-decoration: underline;
          margin-left: 5px;
        }
        &Content {
          width: calc(100% - 45px);
          min-width: calc(100% - 45px);
        }
        i {
          color: $ctertiary;
          font-size: 2rem;
          margin-right: 10px;
        }
      }
      &Works {
        margin: 30px 0;
        &Container {
          margin-bottom: 20px;
        }
        &Details {
          margin-top: 20px;
        }
        &Desclaimer {
          font-weight: bold;
          font-size: 1.4rem;
          font-style: italic;
        }
      }
      &Energy {
        position: relative;
        padding-left: 120px;
        margin-top: 20px;
        &Label {
          position: absolute;
          top: 0;
          &[data-letter='A'] {
            color: $clettera;
            left: -228px;
          }
          &[data-letter='B'] {
            color: $cletterb;
            left: -242px;
          }
          &[data-letter='C'] {
            color: $cletterc;
            left: -255px;
          }
          &[data-letter='D'] {
            color: $cletterd;
            left: -268px;
          }
          &[data-letter='E'] {
            color: $clettere;
            left: -282px;
          }
          &[data-letter='F'] {
            color: $cletterf;
            left: -295px;
          }
          &[data-letter='G'] {
            color: $cletterg;
            left: -308px;
          }
        }
      }
      .diagnosticResultEnergy {
        position: absolute;
        &[data-letter='A'] {
          left: 238px;
          bottom: 180px;
        }
        &[data-letter='B'] {
          left: 252px;
          bottom: 150px;
        }
        &[data-letter='C'] {
          left: 265px;
          bottom: 120px;
        }
        &[data-letter='D'] {
          left: 278px;
          bottom: 90px;
        }
        &[data-letter='E'] {
          left: 292px;
          bottom: 60px;
        }
        &[data-letter='F'] {
          left: 305px;
          bottom: 30px;
        }
        &[data-letter='G'] {
          left: 318px;
          bottom: 0px;
        }
      }
    }
    &Header {
      padding: 35px 60px;
      .diagnosticResultConfort {
        margin-bottom: 30px;
      }
      &Top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 85, 110, 0.2);
        padding-bottom: 30px;
        margin-bottom: 30px;
        & > div:first-child {
          flex: 1;
          margin-right: 20px;
        }
        &Label {
          margin-left: -100px;
          margin-bottom: 30px;
          background: $cprimary;
          color: $cwhite;
          @include border-radius(10px);
          padding: 10px 30px 15px;
          display: inline-block;
          &First {
            font-size: 2.4rem;
            line-height: 3rem;
            font-weight: 700;
          }
          &Second {
            font-size: 1.6rem;
          }
        }
        &Gaz {
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .diagnosticResultEnergy {
            justify-content: flex-end;
            &Infos strong {
              font-size: 2rem;
            }
          }
        }
        &Price {
          color: $cwhite;
          background: $cprimary;
          border: 1px solid $cprimary;
          @include border-radius(10px);
          padding: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          &Label {
            font-weight: 700;
            font-size: 4.8rem;
            line-height: 4.8rem;
          }
        }
      }
      &Badge {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &Item {
          background: $cwhite;
          padding: 9.5px 18px;
          @include border-radius(25px);
          font-weight: 700;
          display: inline-flex;
          align-items: center;
          margin-bottom: 10px;
          line-height: 1.4rem;
          height: 50px;
          img {
            min-width: 25px;
            width: 25px;
            margin-right: 10px;
          }
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
  &Confort {
    width: 100%;
    max-width: 300px;
    height: 10px;
    @include border-radius(15px);
    background: rgba(0, 85, 110, 0.07);
    position: relative;
    margin-bottom: 50px;
    margin-top: 25px;
    &.multiple {
      margin-top: 30px;
      margin-bottom: 60px;
      &:before,
      &:after {
        display: none;
      }
    }
    &Label {
      position: absolute;
      bottom: -23px;
      font-size: 1.2rem;
      &.first {
        left: 0;
      }
      &.last {
        right: 0;
      }
    }
    &[data-level='1'],
    &[data-level='1.1'],
    &[data-level='1.2'],
    &[data-level='1.3'],
    &[data-level='1.4'],
    &[data-level='1.5'],
    &[data-level='1.6'],
    &[data-level='1.7'],
    &[data-level='1.8'],
    &[data-level='1.9'] {
      &:before {
        background: $clevel1;
        left: 0;
        @include transform(translateY(-50%));
      }
      &:after {
        width: 0;
        background: none;
      }
    }
    &[data-level='2'],
    &[data-level='2.1'],
    &[data-level='2.2'],
    &[data-level='2.3'],
    &[data-level='2.4'],
    &[data-level='2.5'],
    &[data-level='2.6'],
    &[data-level='2.7'],
    &[data-level='2.8'],
    &[data-level='2.9'] {
      &:before {
        background: $clevel2;
        left: 12.5%;
        @include transform(translateY(-50%) translateX(-12.5%));
      }
      &:after {
        width: 20%;
        background-image: linear-gradient(-89deg, #ff5403 50%, #d7221f 100%);
      }
    }
    &[data-level='3'],
    &[data-level='3.1'],
    &[data-level='3.2'],
    &[data-level='3.3'],
    &[data-level='3.4'],
    &[data-level='3.5'],
    &[data-level='3.6'],
    &[data-level='3.7'],
    &[data-level='3.8'],
    &[data-level='3.9'] {
      &:before {
        background: $clevel3;
        left: 25%;
        @include transform(translateY(-50%) translateX(-25%));
      }
      &:after {
        width: 30%;
        background-image: linear-gradient(265deg, #ff7800 29%, #d7221f 100%);
      }
    }
    &[data-level='4'],
    &[data-level='4.1'],
    &[data-level='4.2'],
    &[data-level='4.3'],
    &[data-level='4.4'],
    &[data-level='4.5'],
    &[data-level='4.6'],
    &[data-level='4.7'],
    &[data-level='4.8'],
    &[data-level='4.9'] {
      &:before {
        background: $clevel4;
        left: 37.5%;
        @include transform(translateY(-50%) translateX(-37.5%));
      }
      &:after {
        width: 40%;
        background-image: linear-gradient(-89deg, #ffca03 12%, #d7221f 100%);
      }
    }
    &[data-level='5'],
    &[data-level='5.1'],
    &[data-level='5.2'],
    &[data-level='5.3'],
    &[data-level='5.4'],
    &[data-level='5.5'],
    &[data-level='5.6'],
    &[data-level='5.7'],
    &[data-level='5.8'],
    &[data-level='5.9'] {
      &:before {
        background: $clevel5;
        left: 50%;
        @include transform(translateY(-50%) translateX(-50%));
      }
      &:after {
        width: 50%;
        background-image: linear-gradient(-89deg, #ffca03 12%, #d7221f 100%);
      }
    }
    &[data-level='6'],
    &[data-level='6.1'],
    &[data-level='6.2'],
    &[data-level='6.3'],
    &[data-level='6.4'],
    &[data-level='6.5'],
    &[data-level='6.6'],
    &[data-level='6.7'],
    &[data-level='6.8'],
    &[data-level='6.9'] {
      &:before {
        background: $clevel6;
        left: 60%;
        @include transform(translateY(-50%) translateX(-60%));
      }
      &:after {
        width: 60%;
        background-image: linear-gradient(266deg, #cfd30e 10%, #ffca03 32%, #ffa400 57%, #ff7800 80%, #ef1713 100%);
      }
    }
    &[data-level='7'],
    &[data-level='7.1'],
    &[data-level='7.2'],
    &[data-level='7.3'],
    &[data-level='7.4'],
    &[data-level='7.5'],
    &[data-level='7.6'],
    &[data-level='7.7'],
    &[data-level='7.8'],
    &[data-level='7.9'] {
      &:before {
        background: $clevel7;
        left: 70%;
        @include transform(translateY(-50%) translateX(-70%));
      }
      &:after {
        width: 70%;
        background-image: linear-gradient(266deg, #a9cc26 10%, #ffca03 32%, #ffa400 57%, #ff7800 80%, #ef1713 100%);
      }
    }
    &[data-level='8'],
    &[data-level='8.1'],
    &[data-level='8.2'],
    &[data-level='8.3'],
    &[data-level='8.4'],
    &[data-level='8.5'],
    &[data-level='8.6'],
    &[data-level='8.7'],
    &[data-level='8.8'],
    &[data-level='8.9'] {
      &:before {
        background: $clevel8;
        left: 80%;
        @include transform(translateY(-50%) translateX(-80%));
      }
      &:after {
        width: 80%;
        background-image: linear-gradient(-89deg, #73c83a 7%, #cfd30e 25%, #ffca03 44%, #ffa400 61%, #ff7800 81%, #ef1713 100%);
      }
    }
    &[data-level='9'],
    &[data-level='9.1'],
    &[data-level='9.2'],
    &[data-level='9.3'],
    &[data-level='9.4'],
    &[data-level='9.5'],
    &[data-level='9.6'],
    &[data-level='9.7'],
    &[data-level='9.8'],
    &[data-level='9.9'] {
      &:before {
        background: $clevel9;
        left: 90%;
        @include transform(translateY(-50%) translateX(-90%));
      }
      &:after {
        width: 90%;
        background-image: linear-gradient(-89deg, #08aa08 7%, #cfd30e 25%, #ffca03 44%, #ffa400 61%, #ff7800 81%, #ef1713 100%);
      }
    }
    &[data-level='10'] {
      &:before {
        background: $clevel10;
        right: 0;
        @include transform(translateY(-50%));
      }
      &:after {
        width: 100%;
        background-image: linear-gradient(-89deg, #098e3b 7%, #cfd30e 25%, #ffca03 44%, #ffa400 61%, #ff7800 81%, #ef1713 100%);
      }
    }
    &:before {
      content: attr(data-level);
      width: 35px;
      min-width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $cwhite;
      @include border-radius(50%);
      position: absolute;
      top: 50%;
      font-weight: 700;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      z-index: 2;
    }
    &:after {
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      @include border-radius(15px);
    }
    &Time {
      &:before {
        content: attr(data-level);
        width: 35px;
        min-width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $cwhite;
        @include border-radius(50%);
        position: absolute;
        top: 50%;
        font-weight: 700;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        z-index: 2;
      }
      &:after {
        content: '';
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        @include border-radius(15px);
      }
      span {
        position: absolute;
        bottom: -32px;
        font-size: 1.2rem;
        width: 80px;
        &.top {
          bottom: auto;
          top: -33px;
        }
      }
      &[data-level='1'],
      &[data-level='1.1'],
      &[data-level='1.2'],
      &[data-level='1.3'],
      &[data-level='1.4'],
      &[data-level='1.5'],
      &[data-level='1.6'],
      &[data-level='1.7'],
      &[data-level='1.8'],
      &[data-level='1.9'] {
        &:before {
          background: $clevel1;
          left: 0;
          @include transform(translateY(-50%));
        }
        &:after {
          width: 0;
          background: none;
        }
        span {
          left: 0px;
        }
      }
      &[data-level='2'],
      &[data-level='2.1'],
      &[data-level='2.2'],
      &[data-level='2.3'],
      &[data-level='2.4'],
      &[data-level='2.5'],
      &[data-level='2.6'],
      &[data-level='2.7'],
      &[data-level='2.8'],
      &[data-level='2.9'] {
        &:before {
          background: $clevel2;
          left: 12.5%;
          @include transform(translateY(-50%) translateX(-12.5%));
        }
        &:after {
          width: 20%;
          background-image: linear-gradient(-89deg, #ff5403 50%, #d7221f 100%);
        }
        span {
          left: calc(12.5% - 15px);
          @include transform(translateX(-12.5%));
        }
      }
      &[data-level='3'],
      &[data-level='3.1'],
      &[data-level='3.2'],
      &[data-level='3.3'],
      &[data-level='3.4'],
      &[data-level='3.5'],
      &[data-level='3.6'],
      &[data-level='3.7'],
      &[data-level='3.8'],
      &[data-level='3.9'] {
        &:before {
          background: $clevel3;
          left: 25%;
          @include transform(translateY(-50%) translateX(-25%));
        }
        &:after {
          width: 30%;
          background-image: linear-gradient(265deg, #ff7800 29%, #d7221f 100%);
        }
        span {
          left: calc(25% - 10px);
          @include transform(translateX(-25%));
        }
      }
      &[data-level='4'],
      &[data-level='4.1'],
      &[data-level='4.2'],
      &[data-level='4.3'],
      &[data-level='4.4'],
      &[data-level='4.5'],
      &[data-level='4.6'],
      &[data-level='4.7'],
      &[data-level='4.8'],
      &[data-level='4.9'] {
        &:before {
          background: $clevel4;
          left: 37.5%;
          @include transform(translateY(-50%) translateX(-37.5%));
        }
        &:after {
          width: 40%;
          background-image: linear-gradient(-89deg, #ffca03 12%, #d7221f 100%);
        }
        span {
          left: calc(37.5% - 5px);
          @include transform(translateX(-37.5%));
        }
      }
      &[data-level='5'],
      &[data-level='5.1'],
      &[data-level='5.2'],
      &[data-level='5.3'],
      &[data-level='5.4'],
      &[data-level='5.5'],
      &[data-level='5.6'],
      &[data-level='5.7'],
      &[data-level='5.8'],
      &[data-level='5.9'] {
        &:before {
          background: $clevel5;
          left: 50%;
          @include transform(translateY(-50%) translateX(-50%));
        }
        &:after {
          width: 50%;
          background-image: linear-gradient(-89deg, #ffca03 12%, #d7221f 100%);
        }
        span {
          left: 50%;
          @include transform(translateX(-50%));
        }
      }
      &[data-level='6'],
      &[data-level='6.1'],
      &[data-level='6.2'],
      &[data-level='6.3'],
      &[data-level='6.4'],
      &[data-level='6.5'],
      &[data-level='6.6'],
      &[data-level='6.7'],
      &[data-level='6.8'],
      &[data-level='6.9'] {
        &:before {
          background: $clevel6;
          left: 60%;
          @include transform(translateY(-50%) translateX(-60%));
        }
        &:after {
          width: 60%;
          background-image: linear-gradient(266deg, #cfd30e 10%, #ffca03 32%, #ffa400 57%, #ff7800 80%, #ef1713 100%);
        }
        span {
          left: calc(60% + 5px);
          @include transform(translateX(-60%));
        }
      }
      &[data-level='7'],
      &[data-level='7.1'],
      &[data-level='7.2'],
      &[data-level='7.3'],
      &[data-level='7.4'],
      &[data-level='7.5'],
      &[data-level='7.6'],
      &[data-level='7.7'],
      &[data-level='7.8'],
      &[data-level='7.9'] {
        &:before {
          background: $clevel7;
          left: 70%;
          @include transform(translateY(-50%) translateX(-70%));
        }
        &:after {
          width: 70%;
          background-image: linear-gradient(266deg, #a9cc26 10%, #ffca03 32%, #ffa400 57%, #ff7800 80%, #ef1713 100%);
        }
        span {
          left: calc(70% + 10px);
          @include transform(translateX(-70%));
        }
      }
      &[data-level='8'],
      &[data-level='8.1'],
      &[data-level='8.2'],
      &[data-level='8.3'],
      &[data-level='8.4'],
      &[data-level='8.5'],
      &[data-level='8.6'],
      &[data-level='8.7'],
      &[data-level='8.8'],
      &[data-level='8.9'] {
        &:before {
          background: $clevel8;
          left: 80%;
          @include transform(translateY(-50%) translateX(-80%));
        }
        &:after {
          width: 80%;
          background-image: linear-gradient(-89deg, #73c83a 7%, #cfd30e 25%, #ffca03 44%, #ffa400 61%, #ff7800 81%, #ef1713 100%);
        }
        span {
          left: calc(80% + 15px);
          @include transform(translateX(-80%));
        }
      }
      &[data-level='9'],
      &[data-level='9.1'],
      &[data-level='9.2'],
      &[data-level='9.3'],
      &[data-level='9.4'],
      &[data-level='9.5'],
      &[data-level='9.6'],
      &[data-level='9.7'],
      &[data-level='9.8'],
      &[data-level='9.9'] {
        &:before {
          background: $clevel9;
          left: 90%;
          @include transform(translateY(-50%) translateX(-90%));
        }
        &:after {
          width: 90%;
          background-image: linear-gradient(-89deg, #08aa08 7%, #cfd30e 25%, #ffca03 44%, #ffa400 61%, #ff7800 81%, #ef1713 100%);
        }
        span {
          left: calc(90% + 20px);
          @include transform(translateX(-90%));
        }
      }
      &[data-level='10'] {
        &:before {
          background: $clevel10;
          right: 0;
          @include transform(translateY(-50%));
        }
        &:after {
          width: 100%;
          background-image: linear-gradient(-89deg, #098e3b 7%, #cfd30e 25%, #ffca03 44%, #ffa400 61%, #ff7800 81%, #ef1713 100%);
        }
        span {
          right: 0;
        }
      }
    }
  }
  &Energy,
  &PerfEnergyItem {
    display: flex;
    align-items: center;
    &.sameLine {
      margin-bottom: 25px;
    }
    &[data-letter='A'] {
      .diagnosticResultEnergy {
        &Label {
          background: $clettera;
          &:before {
            border-color: transparent $clettera transparent transparent;
          }
        }
        &Infos {
          color: $clettera;
        }
      }
    }
    &[data-letter='B'] {
      .diagnosticResultEnergy {
        &Label {
          background: $cletterb;
          &:before {
            border-color: transparent $cletterb transparent transparent;
          }
        }
        &Infos {
          color: $cletterb;
        }
      }
    }
    &[data-letter='C'] {
      .diagnosticResultEnergy {
        &Label {
          background: $cletterc;
          &:before {
            border-color: transparent $cletterc transparent transparent;
          }
        }
        &Infos {
          color: $cletterc;
        }
      }
    }
    &[data-letter='D'] {
      .diagnosticResultEnergy {
        &Label {
          background: $cletterd;
          &:before {
            border-color: transparent $cletterd transparent transparent;
          }
        }
        &Infos {
          color: $cletterd;
        }
      }
    }
    &[data-letter='E'] {
      .diagnosticResultEnergy {
        &Label {
          background: $clettere;
          &:before {
            border-color: transparent $clettere transparent transparent;
          }
        }
        &Infos {
          color: $clettere;
        }
      }
    }
    &[data-letter='F'] {
      .diagnosticResultEnergy {
        &Label {
          background: $cletterf;
          &:before {
            border-color: transparent $cletterf transparent transparent;
          }
        }
        &Infos {
          color: $cletterf;
        }
      }
    }
    &[data-letter='G'] {
      .diagnosticResultEnergy {
        &Label {
          background: $cletterg;
          &:before {
            border-color: transparent $cletterg transparent transparent;
          }
        }
        &Infos {
          color: $cletterg;
        }
      }
    }
    &Label {
      color: $cwhite;
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: 700;
      position: relative;
      height: 24px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-right: 20px;
      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 10px 12px 0;
        position: absolute;
        left: -10px;
        top: 0;
      }
    }
    &Infos {
      strong {
        font-size: 1.6rem;
      }
    }
  }
  &Perf {
    background: $cbg;
    padding: 65px 0 55px;
    margin-bottom: 50px;
    hr {
      background-color: rgba(15, 38, 74, 0.2);
      margin: 20px 0;
      opacity: 1;
    }
    &Title {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.8rem;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-top: 30px;
      }
    }
    &Alert {
      color: $calert;
      display: flex;
      margin-bottom: 15px;
      i {
        margin-right: 10px;
        font-size: 1.5rem;
      }
      &Label {
        font-size: 1.3rem;
      }
    }
    &House {
      list-style: none;
      margin: 0;
      padding: 0;
      &Item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
        &Picto {
          width: 20px;
          color: $ctertiary;
          margin-right: 20px;
          i {
            font-size: 2rem;
            &.icon-stats {
              font-size: 1.5rem;
            }
          }
        }
        &Label {
          font-size: 1.4rem;
          line-height: 2.2rem;
          &.active {
            font-weight: 700;
            color: $ctertiary;
          }
        }
      }
    }
    &Losses {
      background: $cwhite;
      list-style: none;
      padding: 0;
      margin: 0;
      &Item {
        display: flex;
        align-items: center;
        padding: 10px 30px;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(15, 38, 74, 0.2);
        }
        &:first-child,
        &:nth-child(2) {
          background: $ctertiary;
          color: $cwhite;
          border-bottom: 1px solid $cwhite;
          .diagnosticResultPerfLossesItemPicto {
            color: $cwhite;
          }
        }
        &Picto {
          width: 30px;
          color: $ctertiary;
          margin-right: 20px;
          i {
            font-size: 2.5rem;
            &.icon-roof {
              font-size: 3rem;
            }
            &.icon-air {
              font-size: 2rem;
            }
          }
        }
        &Number {
          font-weight: 700;
          margin-right: 10px;
          font-size: 2rem;
          min-width: 50px;
          width: 50px;
        }
      }
    }
    &Efficiency {
      display: flex;
      align-items: center;
      i {
        font-size: 2rem;
        margin-right: 15px;
      }
      &.bad {
        color: $cbad;
      }
      &.good {
        color: $cgood;
      }
    }
    &Energy {
      position: relative;
      &List {
        list-style: none;
        margin: 0;
        padding: 0;
        &Item {
          color: $cwhite;
          height: 24px;
          position: relative;
          padding-left: 11px;
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 2rem;
          text-transform: uppercase;
          &:not(:last-child) {
            margin-bottom: 6px;
          }
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12px 0 12px 10px;
            position: absolute;
            right: -10px;
            top: 0;
          }
          &:nth-child(1) {
            background: $clettera;
            width: 98px;
            &:after {
              border-color: transparent transparent transparent $clettera;
            }
          }
          &:nth-child(2) {
            background: $cletterb;
            width: 112px;
            &:after {
              border-color: transparent transparent transparent $cletterb;
            }
          }
          &:nth-child(3) {
            background: $cletterc;
            width: 125px;
            &:after {
              border-color: transparent transparent transparent $cletterc;
            }
          }
          &:nth-child(4) {
            background: $cletterd;
            width: 138px;
            &:after {
              border-color: transparent transparent transparent $cletterd;
            }
          }
          &:nth-child(5) {
            background: $clettere;
            width: 152px;
            &:after {
              border-color: transparent transparent transparent $clettere;
            }
          }
          &:nth-child(6) {
            background: $cletterf;
            width: 165px;
            &:after {
              border-color: transparent transparent transparent $cletterf;
            }
          }
          &:nth-child(7) {
            background: $cletterg;
            width: 178px;
            &:after {
              border-color: transparent transparent transparent $cletterg;
            }
          }
        }
      }
      &Item {
        position: absolute;
        display: block;
        &[data-letter='A'] {
          bottom: 167.5px;
          left: 118px;
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          .diagnosticResultPerfEnergyItem {
            &Label {
              margin-top: 0;
              margin-right: 12px;
            }
          }
        }
        &[data-letter='B'] {
          bottom: 150px;
          left: 132px;
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-end;
          .diagnosticResultPerfEnergyItem {
            &Label {
              margin-top: 0;
              margin-right: 12px;
            }
          }
        }
        &[data-letter='C'] {
          bottom: 120px;
          left: 145px;
        }
        &[data-letter='D'] {
          bottom: 90px;
          left: 158px;
        }
        &[data-letter='E'] {
          bottom: 60px;
          left: 172px;
        }
        &[data-letter='F'] {
          bottom: 30px;
          left: 185px;
        }
        &[data-letter='G'] {
          bottom: 0px;
          left: 198px;
        }
        &Infos {
          @extend .diagnosticResultEnergyInfos;
          line-height: 1.6rem;
          strong:first-child {
            font-size: 1.6rem;
          }
        }
        &Label {
          @extend .diagnosticResultEnergyLabel;
          margin-right: 0;
          margin-top: 12px;
        }
      }
    }
  }
  &Modal {
    .modal {
      &-dialog {
        max-width: 1180px;
      }
      &-header {
        padding: 60px 80px 0;
        align-items: flex-start;
      }
      &-body {
        padding: 25px 80px 60px;
      }
    }
  }
  &TableWorks {
    color: $cprimary;
    &.table-striped > tbody > tr:nth-of-type(odd) > * {
      color: $cprimary;
      background: rgba(15, 38, 74, 0.1);
    }
    & > :not(:first-child) {
      border: 0 !important;
    }
    & > :not(caption) > * > * {
      border-bottom: 0;
      box-shadow: none;
    }
    &Thead {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: normal;
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      color: $cprimary;
      i {
        color: $ctertiary;
        font-size: 3rem;
        margin-bottom: 10px;
      }
    }
    &Check {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      i.icon-validate-on {
        font-size: 3rem;
      }
    }
    tr {
      td {
        text-align: center;
        padding: 0;
        background: rgba(15, 38, 74, 0.05);
        position: relative;
        color: $cprimary;
        &:first-child {
          text-align: left;
          padding: 13.75px 30px;
          font-weight: 700;
        }
      }
    }
    th {
      vertical-align: top;
    }
  }
  &Save {
    background: $cprimary;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    color: $cwhite;
    z-index: 10;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.5);
    &Container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &Title {
      font-weight: 700;
      font-size: 1.8rem;
    }
    &Cta {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}

.customer {
  &.user-not-connected {
    .diagnosticResultSave {
      top: 0;
      bottom: auto;
      box-shadow: none;
    }
    .diagnosticResultBannerInfosAnchors {
      &.sticky {
        bottom: 0;
        top: auto;
      }
    }
  }
}

.numberWithControls {
  width: 250px;
  position: relative;

  .diagnosticQuestionTextInput {
    text-align: center;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .diagnosticQuestionTextInput,
  .diagnosticQuestionTextUnit,
  .buttonIncrement {
    color: $cprimary;
    font-weight: 800;
    font-size: 20px;
  }

  .buttonIncrement {
    width: 60px;
    height: 60px;
    position: absolute;
    border: 0;
    background-color: transparent;
  }

  .buttonLeft {
    border-right: 2px solid #e5eef085;
  }

  .buttonRight {
    right: 0;
    border-left: 2px solid #e5eef085;
  }

  .diagnosticQuestionTextUnit {
    right: -35px;
  }

  .diagnosticQuestionTextInput.withUnit {
    padding: 0 60px;
  }
}

// Switch Button CSS
.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  height: 40px;
  background: #bfd9e1;
  border-radius: 80px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-label .switch-button {
  content: '';
  position: absolute;
  top: 2px;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s;
  &.on {
    left: 2px;
  }
  &.off {
    right: 4px;
  }
  &.active {
    background: #f4a008;
    color: #ffffff;
  }
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
