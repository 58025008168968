.lottie {
  &__container {
    text-align: center;
    &--centrered {
      width: 100%;
      max-width: 830px;
      margin: auto;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 2rem 0;
    }
    &--middle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 830px;
      margin: auto;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 2rem 0;
    }
  }
  &__title {
    font-weight: 900;
    font-size: x-large;
    padding: 4rem 0 1rem 0;
  }
  &__subtitle {
    font-weight: 400;
    font-size: large;
    padding-top: 1rem;
  }
}
