.footer {
    &Scroll {
        &.show {
            display: flex;
        }
        width: 50px;
        height: 50px;
        position: fixed;
        bottom: 95px;
        right: 20px;
        z-index: 1000;
        background: $ctertiary;
        color: $cwhite;
        font-size: 1rem;
        border: 0;
        @include border-radius(50%);
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include animation(MoveUpDown 1s linear infinite);
    }
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 90px;
  }
  50% {
    bottom: 95px;
  }
}
