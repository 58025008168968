.realisation-travaux {
  &__container {
    max-width: 956px;
    margin: auto;
  }
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 6rem 4rem 4rem 4rem;
  margin: 4rem 0;
  &__title {
    text-align: center;
    font-size: 26px;
    margin: 1rem 0;
  }
  &__subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    &__text {
      font-size: 16px;
      line-height: 20px;
      color: #ad0e0e;
      font-weight: bold;
    }
  }
  &__content {
    margin: 6rem 4rem 2rem 4rem;
    &__item {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 1.5rem 0;

      &__title {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &__prime {
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: 2rem 4rem;
      margin: 6rem 0 2rem 0;

      &__price {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: #f4a008;
      }
      &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #00556e;
      }
      &__disclaimer {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #00556e;
      }
    }

    &__documents {
      &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      &__items {
        display: flex;
        justify-content: space-between;
        max-width: 400px;
        margin: 3rem 0;
        gap: 3rem;
      }
      &__item {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 10px;

        &__text {
          text-transform: uppercase;
          font-style: italic;
          flex: 1;
        }
      }
    }
  }
  .separator {
    max-width: 363px;
    height: 1px;
    background: #979797;
    margin: 4rem auto;
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
  }
}

@media (max-width: 768px) {
  .realisation-travaux {
    padding: 4rem 2rem 2rem 2rem;
    margin: 2rem 0;
    &__title {
      font-size: 20px;
      margin: 1rem 0;
    }
    &__subtitle {
      &__text {
        font-size: 14px;
      }
    }
    &__content {
      margin: 4rem 2rem 1rem 2rem;
      &__item {
        margin: 1rem 0;
        &__title {
          font-size: 16px;
        }
      }
      &__prime {
        padding: 1rem 2rem;
        margin: 4rem 0 1rem 0;
        &__price {
          font-size: 30px;
        }
        &__title {
          font-size: 16px;
        }
        &__disclaimer {
          font-size: 14px;
        }
      }
      &__documents {
        &__title {
          font-size: 14px;
        }
        &__items {
          margin: 2rem 0;
          gap: 2rem;
          flex-direction: column;
        }
        &__item {
          gap: 0.5rem;
        }
      }
    }
    .separator {
      max-width: 200px;
      margin: 2rem auto;
    }
    &__actions {
      gap: 1rem;
      margin-top: 2rem;
    }
  }
}

@media (max-width: 480px) {
  .realisation-travaux {
    &__title {
      font-size: 18px;
    }

    &__content {
      margin: 2rem 0.5rem 1rem 0.5rem;
      &__item {
        &__title {
          font-size: 14px;
        }
      }
      &__prime {
        &__price {
          font-size: 25px;
        }
        &__disclaimer {
          font-size: 14px;
        }
      }
      &__documents {
        &__title {
          font-size: 14px;
        }
        &__items {
          margin: 2rem 0;
          gap: 2rem;
          flex-direction: column;
        }
        &__item {
          gap: 0.5rem;
        }
      }
    }
  }
}
