.information-travaux {
  margin-top: 3rem;

  .step__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #00556e;
    border-radius: 50%;
    color: #ffffff;
    flex-shrink: 0;
  }

  &__content {
    margin-top: 2rem;
    &__questions {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      &__question {
        cursor: pointer;
        position: relative;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 22%;
        min-height: 100px;
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 15px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #252525;
          margin: 0;
        }
        &__state {
          position: absolute;
          bottom: -10px;
          margin: 0;
          z-index: 1;
        }
        &.grised {
          background: #f2f2f2;
        }
      }
    }

    &__devis {
      margin-top: 6rem;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: 4rem 4rem 6rem 4rem;
      &__container {
        margin-top: 2rem;
        display: flex;
        gap: 4rem;
      }

      &__uploads {
        &__fields {
          flex: 1.2;
        }

        &__avertissement {
          display: flex;
          align-items: flex-start;
          gap: 2rem;
          margin: 1rem 0;
        }

        &__wrapper {
          flex: 1;
        }
        &__date {
          margin: 2rem 0;
        }
      }

      &__questions {
        &__actions {
          margin-top: 4rem;
          .animated-check {
            animation: fadeIn 0.5s ease-in-out;
            @keyframes fadeIn {
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          }
        }
        &__question {
          padding: 6rem 4rem;
        }
        &__result {
          padding: 4rem 0;
        }
      }
    }

    &__rib,
    &__ah {
      margin-top: 6rem;
      gap: 6%;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      padding: 4rem 4rem 6rem 4rem;
      &__uploads {
        margin: 4rem auto 0 auto;
        max-width: 400px;
      }
    }

    &__ah {
      &__information {
        display: flex;
        margin: 2rem 0;
        gap: 2rem;

        &__icon {
          color: #f4a008;
          font-size: 30px;
          line-height: normal;
        }

        &__text {
          color: #f4a008;
        }
      }

      &__items {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 2rem;
        margin-bottom: 4rem;
        &__item {
          display: flex;
          gap: 1rem;
          width: 30%;
          &__number {
            flex-shrink: 0;
            font-size: 14px;
            color: #ffffff;
            background: #00556e;
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }
          &__text {
            color: #252525;
            &__result {
              margin-top: 1rem;
              color: #00a651;
              font-weight: bold;
            }
            &__uploader {
              margin-top: 2rem;
            }
          }
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      padding: 8rem 2rem 4rem 2rem;
    }
  }
  .devis-rai-verify {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #db2b2b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 4rem;
    &__icon-info {
      margin-left: 0.5rem;
    }
  }

  .file-uploader__cta {
    min-height: 164px;
    height: 164px;
  }
  .file-uploader__qa {
    background: #fbfbfb;
    padding: 5rem 4rem;
    border-radius: 0 0 20px 20px;
    background: linear-gradient(180deg, rgba(251, 251, 251, 0.5) 0%, rgba(251, 251, 251, 1) 100%);
    margin-top: -2rem;
    &__steps {
      display: flex;
      align-items: center;
      gap: 2rem;

      &__title {
        font-size: 24px;
        font-weight: 700;
      }
      &__indicator {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        &__step {
          width: 30px;
          height: 10px;
          border: 1px solid #00556e;
          border-radius: 10px;
          &__state {
            padding-left: 2rem;
            color: #00556e;
            font-size: 18px;
            font-weight: 700;
          }
          &.active {
            background: #f4a008;
            border-color: #f4a008;
          }
          &.answered {
            background: #00556e;
            border-color: #00556e;
          }
          &.not-answered {
            background: #db7927;
            border-color: #db7927;
          }
        }
      }
    }
    &__question {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4rem;
      &__cta {
        display: flex;
        gap: 1rem;
      }
    }
  }
}

// responsive
@media (max-width: 1024px) {
  .information-travaux {
    &__content {
      &__questions {
        &__question {
          width: 30%;
          padding: 1rem;
        }
      }

      &__ah {
        &__items {
          &__item {
            width: 45%;
            margin: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .information-travaux {
    &__content {
      &__questions {
        &__question {
          width: 45%;
        }
      }

      &__devis {
        &__container {
          flex-direction: column;
          max-width: 400px;
          margin: 1rem auto;
          gap: 1rem;
        }
        &__questions,
        &__uploads {
          width: 100%;
        }
        &__questions {
          &::after {
            display: none;
          }
          &__question {
            padding: 2rem;
          }
        }
      }

      &__ah {
        &__items {
          &__item {
            width: 100%;
            margin: 1rem 0;
          }
        }
      }
    }
    .file-uploader__cta {
      min-height: auto;
      height: auto;
    }
    .file-uploader__qa {
      &__steps {
        &__title {
          display: none;
        }
        &__indicator {
          &__step {
            width: 10px;
          }
        }
      }
      &__question {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        &__cta {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .information-travaux {
    &__content {
      &__questions {
        &__question {
          width: 100%;
          padding: 1rem;
        }
      }
      &__rib,
      &__ah {
        padding: 2rem 15px;
      }
    }
  }
}
