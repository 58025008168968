.mtxs { margin-top: 5px; }
.mts { margin-top: 10px; }
.mtm { margin-top: 20px; }
.mtl { margin-top: 30px; }
.mtn { margin-top: 0; }
.mrxs { margin-right: 5px; }
.mrs { margin-right: 10px; }
.mrm { margin-right: 20px; }
.mrl { margin-right: 30px; }
.mrn { margin-right: 0; }
.mbxs { margin-bottom: 5px; }
.mbs { margin-bottom: 10px; }
.mbm { margin-bottom: 20px; }
.mbl { margin-bottom: 30px; }
.mbn { margin-bottom: 0; }
.mbxl { margin-bottom: 40px; }
.mlxs { margin-left: 5px; }
.mls { margin-left: 10px; }
.mlm { margin-left: 20px; }
.mll { margin-left: 30px; }
.mln { margin-left: 0; }
.nm	{ margin: 0; }

.spacing { padding: 20px; }
.pts { padding-top: 10px; }
.ptm { padding-top: 20px; }
.ptl { padding-top: 30px; }
.ptn { padding-top: 0; }
.prs { padding-right: 10px; }
.prm { padding-right: 20px; }
.prl { padding-right: 30px; }
.prn { padding-right: 0; }
.pbs { padding-bottom: 10px; }
.pbm { padding-bottom: 20px; }
.pbl { padding-bottom: 30px; }
.pbn { padding-bottom: 0; }
.pls { padding-left: 10px; }
.plm { padding-left: 20px; }
.pll { padding-left: 30px; }
.pln { padding-left: 0; }
.np { padding: 0; }

.tdn { text-decoration: none; }
.tdu { text-decoration: underline; }
.ttu { text-transform: uppercase; }
.dib { display: inline-block; }
.cb { clear: both; }
.tac { text-align: center; }
