.error-messages {
  display: flex;
  align-items: center;
  color: #d8000c;
  margin-top: 10px;
  animation: fadeIn 0.5s ease;

  & .error-icon {
    margin-right: 10px;
    color: #d8000c;
    font-size: 16px;
    flex-shrink: 0;
  }

  & .error-text {
    font-size: 14px;
    font-weight: 600;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
