.dropzone {
  height: 100%;
  min-height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #00556e;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;

  &.mh-50 {
    min-height: 50px;
  }
  &.notAllowed {
    border: 1px dashed #ccc;
    background: #f9f9f9;
    cursor: not-allowed;
  }
  transition: border-color 0.3s ease;

  &.active {
    border-color: #2196f3;
  }
}

.loading-icon {
  margin-right: 8px;
  font-size: 24px;
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-text {
    margin-left: 2px;
    margin-bottom: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  &.notAllowed {
    svg {
      color: #f2f2f2;
    }
    h3 {
      color: #f2f2f2;
    }
  }
}

.subtext {
  font-size: 12px;
  color: #666;
}

.file-list {
  height: 100%;
}

.file-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-top: 5px;
  height: 100%;
  width: 100%;
}

.file-info {
  display: flex;
  align-items: center;
  overflow: hidden;
  line-clamp: 1;
  -webkit-line-clamp: 1;
}
.file-name {
  font-weight: bold;
  text-decoration: underline;
  overflow-wrap: break-word;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.file-actions {
  display: flex;
  flex-direction: column;
}

.edit-btn,
.remove-btn {
  color: #00556e;
  border: none;
  background: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.error-message {
  color: #d9534f;
  background-color: #f2dede;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;

  .error-icon {
    margin-right: 5px;
  }
}

.upload-btn {
  background-color: #5bc0de;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;

  &:disabled {
    background-color: #bce8f1;
    cursor: not-allowed;
  }
}

// shake animation

.delete-button-container {
  margin: 2rem 0 0 0;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  gap: 1rem;
  .delete-button-info {
    display: flex;
    gap: 1rem;
    align-items: center;
    &__text {
      overflow-wrap: break-word;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .delete-button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    &:hover {
      animation: loading 0.5s;
    }
  }
}

@media screen and (max-width: 1400px) {
  .file-item {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .delete-button-container {
    .delete-button-info {
      &__text {
        max-width: 120px;
      }
    }
  }
}
