@media screen and (max-width: 1399px) {
  .header {
    padding: 0 20px;
    &Nav {
      margin-left: 30px;
      &LeftItem {
        margin: 0 10px;
        &ChildrenCat {
          padding: 30px;
        }
      }
      &RightItem {
        margin: 0 1.2vw;
      }
    }
  }
  .accountSurveyLeft {
    padding: 50px 0;
  }
}

@media screen and (max-width: 1199px) {
  .header {
    padding: 0 30px;
    &Nav {
      margin-left: 50px;
      &Left {
        font-size: 1.4rem;
        &Item {
          margin: 0 20px;
        }
      }
      &Right {
        font-size: 1.3rem;
        &Item {
          &Link {
            i {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .diagnostic {
    &Chapter {
      min-width: 250px;
      padding: 50px 30px;
    }
    &Question {
      min-width: calc(100% - 250px);
      &List.displayColumn .diagnosticQuestionListItem {
        font-size: 1.3rem;
        &Content {
          padding: 40px 15px 20px;
        }
      }
    }
    &Footer {
      &Save {
        margin-left: 10px;
      }
    }
  }
  .header {
    &Logo img {
      height: 35px;
    }
    &Nav {
      &Left {
        font-size: 1.4rem;
        &Item {
          margin: 0 10px;
          &Children {
            &Action {
              &Title {
                font-size: 1.6rem;
                line-height: 2rem;
              }
              .btn {
                font-size: 1.2rem;
                padding: 0 10px;
                height: 40px;
                &.btn-icon-right {
                  i {
                    margin-left: 4px;
                  }
                }
              }
            }
            &Cat {
              padding: 30px 20px;
              &Title {
                font-size: 1.8rem;
                line-height: 2.2rem;
              }
              &ListItemLink {
                font-size: 1.3rem;
                line-height: 1.6rem;
              }
            }
          }
        }
      }
      &Right {
        &Item {
          margin: 0;
          font-size: 1.2rem;
          &.headerNavRightItemDiagnostic {
            margin-left: 14px;
            .headerNavRightItemLink span i {
              margin-right: 6px;
            }
          }
          &Link {
            i {
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
  .account {
    &Survey {
      &Title {
        font-size: 3rem;
        &Sub br {
          display: none;
        }
      }
      &Left {
        &None {
          margin-right: calc(4vw - 20px);
        }
      }
      &Right {
        padding: 44px 4vw;
      }
    }
    &Infos {
      &Content {
        padding: 40px 60px;
      }
    }
  }
  .register {
    &Form {
      padding: 30px 50px;
    }
  }

  /*
    * CSS Google optimize
    */
  .goptimize-picto-box {
    margin: 0;
  }
}

@media screen and (max-width: 991px) {
  .header {
    padding: 0 15px;
    justify-content: space-between;
    &Nav {
      width: auto;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      background: $cwhite;
      height: calc(100vh - 70px);
      z-index: 1;
      margin-left: 0;
      display: none;
      overflow: auto;
      &Burger {
        display: block;
      }
      &Left {
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        background: $cwhite;
        height: 100%;
        z-index: 1;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 32px;
        &Item {
          margin: 0;
          width: 100%;
          &:hover {
            .headerNavLeftItemChildren {
              display: none;
            }
            .headerNavLeftItemLink {
              color: $cprimary;
              text-shadow: none;
              &:after {
                width: 0;
              }
            }
          }
          &Link {
            border-bottom: 1px solid rgba(0, 85, 110, 0.1);
            position: relative;
            &.selected {
              background: $cbg;
              width: calc(100% + 64px);
              margin-left: -32px;
              padding-left: 70px;
              font-weight: 700;
              border-color: $cbg;
              &:before {
                right: inherit;
                left: 32px;
                @include transform(translateY(-50%) rotate(0deg));
              }
            }
            &:hover {
              color: $cprimary;
              &:after {
                width: 0;
                color: $cprimary;
              }
            }
            &:before {
              content: '\e902';
              font-family: 'Icomoon';
              color: $ctertiary;
              position: absolute;
              right: 0;
              top: 50%;
              font-size: 1.2rem;
              @include transform(translateY(-50%) rotate(180deg));
            }
          }
          &Children {
            flex-direction: column;
            height: calc(100vh - 140px);
            &Action {
              width: 100%;
              min-width: 100%;
              .btn {
                font-size: 1.4rem;
                padding: 0 20px;
                height: 50px;
              }
              &Title {
                font-size: 1.8rem;
                line-height: 2.4rem;
              }
            }
            &Cat {
              padding: 0;
              margin: 0 32px;
              border-bottom: 1px solid rgba(0, 85, 110, 0.1);
              &:not(:last-child) {
                border-right: 0;
              }
              &Container {
                flex-direction: column;
                width: 100%;
                min-width: 100%;
                z-index: 1;
                & > div {
                  width: calc(100% - 64px);
                  min-width: calc(100% - 64px);
                }
              }
              &Title {
                font-size: 1.4rem;
                font-weight: 400;
                color: $cprimary;
                margin-bottom: 0;
                position: relative;
                cursor: pointer;
                padding: 24px 0;
                &Icon {
                  display: none;
                }
                &:after {
                  content: '+';
                  color: $ctertiary;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  font-size: 2.4rem;
                  @include transform(translateY(-50%) rotate(180deg));
                }
                &.selected {
                  font-weight: 700;
                  &:after {
                    content: '-';
                    font-weight: 400;
                  }
                }
              }
              &List {
                display: none;
                padding-left: 0;
                flex-direction: column;
                margin-bottom: 20px;
              }
            }
          }
        }
        .hideOnMobile {
          display: none;
        }
        .forMobile {
          display: block;
          width: 100%;
          .accountInfosMenu {
            width: 100%;
            padding: 0;
            min-height: auto;
            .accordion-body {
              padding: 10px;
            }
            .accordion-button {
              height: 45px;
              &:after {
                content: '\e902';
                transform: translateY(-50%) rotate(180deg);
                font-size: 0.8rem;
              }
              &:not(.collapsed):after {
                content: '\e901';
                transform: unset;
              }
            }
          }
          .headerNavLeftItem {
            margin: 5px 0;
            a {
              padding: 0 30px;
              height: 45px;
              background: #00556e;
              color: #ffffff;
              &::before {
                display: none;
              }
              &:hover {
                color: #f4a008;
              }
            }
          }
        }
      }
      &Right {
        &Item {
          &.headerNavRightItemDiagnostic {
            position: fixed;
            bottom: 0;
            left: 0;
            margin: 0;
            width: 100%;
            height: 55px;
            z-index: 2;
            .headerNavRightItemLink {
              background: $csecondary;
              color: $cwhite;
              display: flex;
              position: relative;
              z-index: 1;
              align-items: center;
              justify-content: center;
              height: 100%;
              font-size: 1.3rem;
            }
          }
          &.headerNavRightItemAccount {
            i {
              margin-right: 0;
            }
            span {
              display: none;
            }
          }
        }
      }
    }
  }
  .diagnostic {
    &Question {
      padding: 30px 50px 55px;
      &List {
        &.displayColumn {
          .diagnosticQuestionListItem {
            width: calc((100% - 20px) / 2);
            padding-bottom: calc((100% - 20px) / 2);
          }
        }
      }
      &Start {
        &Intro {
          flex-direction: column-reverse;
          margin-bottom: 40px;
          &Text {
            margin-top: 40px;
            margin-right: 0;
            &Title {
              font-size: 2.4rem;
              line-height: 2.6rem;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    &Rejected {
      flex-direction: column;
      align-items: center;
      &Icon {
        padding-top: 0;
        padding-right: 0;
        margin-bottom: 30px;
        img {
          width: 40vw;
        }
      }
    }
  }
  .account {
    &Survey {
      flex-direction: column;
      &Left {
        min-width: 100%;
        padding: 40px 0px;
        &None {
          margin-right: 0;
        }
      }
      &Right {
        width: 100%;
        min-width: 100%;
        &Item {
          width: calc(25% - 15px);
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &Infos {
      &Content {
        padding: 40px;
        width: calc(100% - 230px);
      }
      &Menu {
        width: 230px;
      }
    }
  }
  .footerScroll  {
    width: 40px;
    height: 40px;
  }
  .modal {
    &-header {
      padding-left: 30px;
      padding-right: 30px;
    }
    &-body {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
    }
  }
  .register {
    &Infos {
      width: 290px;
      min-width: 290px;
      padding: 20px 30px;
    }
    &Form {
      padding: 30px 40px;
      width: calc(100% - 290px);
      min-width: calc(100% - 290px);
      &Header {
        &Login {
          margin-left: 10px;
          &Arrow {
            display: none;
          }
        }
      }
    }
  }

  .goptimize-mask-show {
    .diagnosticResult {
      .diagnosticResultPerf {
        .row {
          max-height: initial;

          .diagnosticResultPerfTitle,
          .diagnosticResultConfort,
          .diagnosticResultPerfTitle,
          .diagnosticResultPerfEfficiency,
          div:last-child {
            display: none !important;
          }
        }
      }
    }
  }

  .goptimize-mask {
    bottom: 60vh;
    height: 100vh;

    .goptimize-mask-transparent {
      height: auto;
    }

    .goptimize-mask-content {
      .goptimize-mask-title {
        font-size: 2.6rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    height: 55px;
    &Nav {
      top: 55px;
      height: calc(100vh - 55px);
      &LeftItemChildren {
        height: calc(100vh - 125px);
      }
      &Right {
        &Item {
          &Link {
            height: 55px;
          }
        }
      }
    }
    &Logo {
      img {
        height: 35px;
      }
    }
  }
  .content {
    padding-top: 55px;
  }
  .loaderEnd {
    &Container {
      min-height: auto;
    }
    &Title {
      font-size: 2.4rem;
      line-height: 2.6rem;
    }
    &List {
      &Item  {
        font-size: 1.3rem;
        &Icon {
          i.icon-glassloader {
            font-size: 2rem;
          }
        }
      }
    }
  }
  .ballLoader {
    width: 20px;
    height: 5px;
    margin-bottom: 5px;
    &Ball {
      height: 5px;
      width: 5px;
    }
  }
  .diagnostic {
    padding-bottom: 50px;
    flex-direction: column;
    position: relative;
    &Chapter {
      height: 50px;
      padding: 10px 35px;
      border-top: 1px solid rgba(0, 85, 110, 0.1);
      border-bottom: 1px solid rgba(0, 85, 110, 0.1);
      &.primes {
        height: 70px;
      }

      &Prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 48px;
        background: $cwhite;
        color: $cprimary;
        position: absolute;
        left: 0;
        top: 1px;
        border: 0;
      }
      &List {
        display: none;
        &Mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          list-style: none;
          padding: 0;
          margin: 0;
          &Item {
            background: rgba(0, 155, 151, 0.15);
            min-width: 28px;
            width: 28px;
            height: 28px;
            @include border-radius(50%);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 18px;
            position: relative;
            &:last-child {
              margin-right: 0;
              &:after {
                display: none;
              }
            }
            &:after {
              content: '';
              width: 8px;
              height: 2px;
              @include border-radius(1px);
              background: $ctertiary;
              margin: 5px;
              position: absolute;
              right: -18px;
              top: 8px;
            }
            &.completed {
              background: $ctertiary;
              color: $cwhite;
              i {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
    &Question {
      min-width: 100%;
      &Label {
        font-size: 2.4rem;
        line-height: 2.6rem;
      }
      &Why {
        &Title {
          font-size: 1.4rem;
          line-height: 2rem;
        }
        &Details {
          font-size: 1.3rem;
          line-height: 2rem;
        }
        &Icon {
          font-size: 3rem;
        }
      }
      &List {
        &:not(.displayColumn) {
          .diagnosticQuestionListItem {
            min-height: 55px;
            font-size: 1.3rem;
            line-height: 1.6rem;
            &Content {
              padding: 10px 20px 10px 60px;
            }
            &:before {
              width: 22px;
              height: 22px;
            }
            &.selected:after {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
    &Rejected {
      padding: 50px 30px;
      &Content {
        &Title {
          font-size: 2.4rem;
          line-height: 2.6rem;
          &Sub {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-bottom: 30px;
          }
        }
      }
    }
    &Footer {
      &Save {
        flex: 1;
        justify-content: center;
        height: 100%;
        margin-left: 0;
        i {
          font-size: 2rem;
        }
      }
      &SaveLabel {
        text-decoration: none;
        font-size: 1.3rem;
        span {
          display: none;
        }
      }
    }
  }
  .account {
    &Menu {
      &Container {
        min-height: calc(100vh - 55px);
        top: 55px;
      }
    }
    &SurveyRightItem {
      width: calc(50% - 10px);
      margin-right: 0;
    }
    &Infos {
      flex-direction: column;
      &Menu {
        width: 100%;
        min-height: auto;
        padding: 0;
      }
      &Content {
        width: 100%;
        padding: 30px;
        form button[type='submit'] {
          margin-top: 0;
        }
      }
      &Title {
        font-size: 2.4rem;
        line-height: 2.4rem;
        margin-bottom: 20px;
        &Sub {
          font-size: 1.4rem;
          line-height: 2rem;
          margin-bottom: 20px;
        }
      }
      &Date {
        font-size: 1.3rem;
        margin-bottom: 20px;
      }
    }
    &ForgetPasswordContainer {
      min-height: calc(100vh - 55px);
    }
  }
  .register {
    flex-direction: column-reverse;
    &:not(.lastStep) {
      padding-bottom: 50px;
    }
    &Infos {
      width: 100%;
      min-width: 100%;
      align-items: flex-start;
      min-height: auto;
      padding: 35px 30px;
      &Illu {
        display: none;
      }
      &Title {
        margin-top: 0;
      }
    }
    &Form {
      width: 100%;
      min-width: 100%;
      padding: 0;
      &Header {
        flex-direction: column;
        &Login {
          text-align: center;
          padding: 10px 30px;
          width: 100%;
          justify-content: center;
          margin-left: 0;
        }
      }
      &Step {
        padding: 10px 30px 25px;
        background: $cbg;
        justify-content: center;
        width: 100%;
        &Item {
          &:not(:last-child):after {
            top: 13px;
          }
          &.validate:before {
            left: 9px;
            font-size: 0.8rem;
          }
          &Number {
            min-width: 28px;
            width: 28px;
            height: 28px;
          }
          &Label {
            font-size: 0.9rem;
            bottom: -20px;
          }
        }
        &Content {
          padding: 30px;
          &Title {
            font-size: 2.4rem;
            line-height: 2.4rem;
          }
          &Details {
            line-height: 2.4rem;
            margin-bottom: 30px;
          }
          &Infos {
            font-size: 1.3rem;
            line-height: 1.8rem;
            margin-bottom: 10px;
          }
          &Cta {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            .btn {
              @include border-radius(0);
              height: 50px;
              width: 50%;
              display: inline-flex;
              justify-content: center;
              &.solo {
                width: 100%;
              }
              &.btn-tertiary {
                margin-right: 0;
              }
            }
          }
          &Confirm {
            min-height: auto;
            &Icon {
              font-size: 3.5rem;
            }
            &Title {
              font-size: 2.4rem;
              line-height: 2.6rem;
            }
            &Details {
              font-size: 1.3rem;
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
  .footer {
    &Scroll {
      bottom: 65px;
      width: 40px;
      height: 40px;
      font-size: 0.8rem;
    }
  }
  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 65px;
    }
    50% {
      bottom: 70px;
    }
  }
  .primes {
    .diagnosticChapterList,
    .diagnosticChapterListMobile {
      justify-content: flex-start;
    }

    .diagnosticChapterListItem {
      &.active.complete {
        max-width: 50px;
      }
    }

    .progressLabel {
      display: block;
      margin-left: 10px;
      .diagnosticChapterListItemLabel {
        font-weight: 700;
        font-size: 14px;
        color: #009b97;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .modal-dialog {
    width: 80%;
    max-width: 780px;
  }
  .header {
    justify-content: space-between;
  }
}

@media screen and (max-width: 575px) {
  .btn {
    font-size: 1.3rem;
  }
  .footerScroll {
    right: 10px;
    width: 30px;
    height: 30px;
  }
  .diagnostic {
    &Question {
      padding: 30px 20px 100px 20px;
      &List {
        &.displayColumn {
          gap: 20px;
        }
        &Item {
          &:before {
            width: 18px;
            height: 18px;
          }
        }
      }
      &Start {
        &Intro {
          padding-left: 0;
          img {
            max-width: 150px;
          }
        }
        &Item {
          flex-direction: column;
          &Icon {
            margin-bottom: 15px;
            img {
              max-width: 30px;
            }
          }
          &Content {
            margin-left: 0;
            &Title,
            &Details {
              font-size: 1.3rem;
              line-height: 2rem;
            }
          }
        }
      }
    }
    &Rejected {
      padding: 20px 30px 30px;
      &Content {
        &Phone {
          font-size: 2rem;
          line-height: 2.4rem;
        }
        &Form {
          &Cta {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .btn {
              width: 100%;
              justify-content: center;
              margin: 5px auto;
            }
          }
          &Checkbox {
            flex-wrap: wrap;
            &Label {
              min-width: 100%;
              margin-bottom: 15px;
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
  .account {
    &MenuList {
      width: 100%;
      &ItemLink {
        padding: 0 30px;
      }
    }
    &Login {
      top: 55px;
      height: calc(100vh - 55px);
      --safe-area-inset-top: env(safe-area-inset-top);
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      height: calc(100% - 55px - (var(--safe-area-inset-top) - var(--safe-area-inset-bottom)));
      &Close {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;
        background: none;
        border: 0;
        color: $cprimary;
      }
      &Overlay {
        display: none;
      }
      &Card {
        width: 100%;
        padding: 30px;
        min-height: calc(100vh - 55px);
      }
    }
    &Survey {
      min-height: calc(100vh - 55px);
      &Title {
        &.forMobile {
          display: block;
        }
        &.forDesktop {
          display: none;
        }
      }
      &List {
        .slick-list {
          width: calc(100% + 10px);
          margin-top: -15px;
        }
        &Card {
          &Body {
            padding: 15px 22px 20px;
            &Actions {
              display: flex;
              justify-content: space-between;
              .btn-primary {
                padding: 0 10px;
                i {
                  display: none;
                }
              }
              .btn-link {
                background: $cbg;
                margin-top: 0;
                height: 50px;
                margin-left: 5px;
                span {
                  display: none;
                }
                &.btn-icon-left i {
                  margin-right: 0;
                }
              }
            }
            &Status {
              margin-bottom: 20px;
            }
          }
        }
      }
      &Left {
        padding: 28px 0 18px 0px;
        &None {
          padding: 20px;
          &Illu {
            height: 70px;
          }
          .accountSurvey {
            &Title {
              &Sub {
                max-width: 80%;
                margin-bottom: 11px;
              }
            }
            &Infos {
              max-width: 80%;
              margin-bottom: 25px;
              &.forMobile {
                display: block;
              }
              &.forDesktop {
                display: none;
              }
            }
          }
          .btn-primary {
            display: flex;
            width: 100%;
            justify-content: center;
          }
        }
        .accountSurvey {
          &Title {
            font-size: 2.4rem;
            line-height: 2.6rem;
            text-align: center;
            margin-bottom: 25px;
            &Sub {
              font-size: 1.4rem;
              line-height: 1.8rem;
              &.forMobile {
                display: block;
              }
              &.forDesktop {
                display: none;
              }
            }
          }
          &Infos {
            font-size: 1.3rem;
            line-height: 1.5rem;
            display: none;
          }
        }
      }
      &Right {
        padding: 20px 0;
        position: relative;
        &:before {
          content: '';
          background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          opacity: 0.3;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        .accountSurveyTitleSub,
        .accountSurveyInfos {
          display: none;
        }
        .slick-slide {
          padding: 0 5px;
          box-sizing: border-box;
          height: inherit;
          & > div {
            height: 100%;
          }
        }
        .slick-track {
          display: flex;
        }
        &Item {
          width: 100%;
          margin-right: 0px;
          margin-bottom: 0;
          height: 100%;
          padding: 18px 20px 15px;
          &:nth-child(2n + 1) {
            margin-right: 0px;
          }
          &Illu {
            img {
              height: 75px;
            }
          }
          &Plus {
            width: 30px;
            height: 30px;
            bottom: 5px;
            right: 5px;
          }
        }
      }
    }
    &InfosPasswordRequired {
      flex-direction: column;
      &Item {
        width: 100%;
      }
    }
    &ForgetPassword {
      &Title {
        font-size: 2.5rem;
        line-height: 2.6rem;
        margin-bottom: 15px;
      }
      &Infos {
        font-size: 1.3rem;
        line-height: 2rem;
        margin-bottom: 35px;
      }
      &Form {
        .btn-primary {
          margin-top: 5px;
        }
      }
      &Picto {
        width: 35px;
        height: 35px;
        font-size: 3.5rem;
        margin-bottom: 25px;
      }
      .btn-primary {
        width: 100%;
        justify-content: center;
      }
    }
  }
  .customCheckbox input[type='checkbox'] + label {
    font-size: 1.3rem;
  }

  .custom__date {
    label {
      flex-direction: column;
    }
  }

  .modal {
    &.modalContact {
      .modal {
        &-content {
          @include border-radius(0);
        }
        &-dialog {
          margin: 0;
        }
      }
    }

    &-form-actions {
      .btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }

  /*
    * CSS Google optimize
    */
  .goptimize-picto-box {
    .goptimize-picto-item {
      width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .diagnostic {
    &Question {
      &Label {
        &Wrapper {
          display: flex;
          align-items: flex-start;
          .icon-info {
            color: $csecondary;
            display: block;
            margin-bottom: 25px;
            margin-right: 10px;
            font-size: 2.5rem;
            cursor: pointer;
          }
        }
      }

      &List {
        &.displayGrid {
          flex-direction: column;
          .diagnosticQuestionListItem {
            width: 100%;
          }
        }
      }
    }
  }
  .primes {
    .diagnostic {
      &Question {
        &Why {
          height: 0;
          opacity: 0;
          transition: all 0.3s ease;
          visibility: hidden;
          margin: 0;

          &Icon {
            display: none;
          }

          &.open {
            height: auto;
            transition: all 0.3s ease;
            opacity: 1;
            visibility: visible;
            margin-bottom: 35px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 374px) {
  .diagnosticChapter {
    padding: 10px;
    &.displayPrev {
      padding-left: 60px;
      &.primes {
        padding-left: 10px;
      }
    }
  }
}
