.progress {
    height: 7px;
    background: rgba(0, 155, 151, 0.15);
    @include border-radius(5px);
    &-bar {
        background: $ctertiary;
    }
}

.CircularProgressbar {
    .CircularProgressbar-path {
      stroke: $ctertiary;
    }
    .CircularProgressbar-trail {
      stroke: transparent;
    }
    .CircularProgressbar-text {
        fill: $ctertiary;
        font-size: 4.5rem;
    }
}

.loader {
    &Simple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        &Container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255,255,255, 0.9);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }
        div {
            position: absolute;
            width: 8px;
            height: 8px;
            background: $ctertiary;
            @include border-radius(50%);
            animation: loaderSimple 1.2s linear infinite;
            &:nth-child(1) {
                animation-delay: 0s;
                top: 37px;
                left: 66px;
            }
            &:nth-child(2) {
                animation-delay: -0.1s;
                top: 22px;
                left: 62px;
            }
            &:nth-child(3) {
                animation-delay: -0.2s;
                top: 11px;
                left: 52px;
            }
            &:nth-child(4) {
                animation-delay: -0.3s;
                top: 7px;
                left: 37px;
            }
            &:nth-child(5) {
                animation-delay: -0.4s;
                top: 11px;
                left: 22px;
            }
            &:nth-child(6) {
                animation-delay: -0.5s;
                top: 22px;
                left: 11px;
            }
            &:nth-child(7) {
                animation-delay: -0.6s;
                top: 37px;
                left: 7px;
            }
            &:nth-child(8) {
                animation-delay: -0.7s;
                top: 52px;
                left: 11px;
            }
            &:nth-child(9) {
                animation-delay: -0.8s;
                top: 62px;
                left: 22px;
            }
            &:nth-child(10) {
                animation-delay: -0.9s;
                top: 66px;
                left: 37px;
            }
            &:nth-child(11) {
                animation-delay: -1s;
                top: 62px;
                left: 52px;
            }
            &:nth-child(12) {
                animation-delay: -1.1s;
                top: 52px;
                left: 62px;
            }
        }
    }
    &End {
        &Container {
            padding: 40px;
            max-width: 740px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 0 auto;
            min-height: calc(100vh - 70px);
        }
        &Icon {
            color: $ctertiary;
            font-size: 5rem;
            margin-bottom: 40px;
        }
        &Title {
            font-size: 3.2rem;
            font-weight: 700;
            margin-bottom: 25px;
            text-align: center;
        }
        &List {
            padding: 0;
            margin: 0;
            list-style: none;
            &Item {
                color: $ctertiary;
                display: flex;
                align-items: center;
                &.disabled {
                    opacity: 0.3;
                }
                &.current {
                    font-weight: 700;
                }
                &Icon {
                    min-width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i.icon-glassloader { font-size: 2.5rem };
                    i.icon-validate-on { font-size: 3rem };
                }
            }
        }
    }
}

$ballLoaderWidth: 50;
.ballLoader {
    width: 28px;
    height: calc($ballLoaderWidth / 3) - 10px;
    position: relative;
    margin-bottom: 8px;
    margin-left: 10px;
    display: inline-block;
    vertical-align: bottom;
    &Ball {
        will-change: transform;
        height: calc($ballLoaderWidth / 3) - 10px;
        width: calc($ballLoaderWidth / 3) - 10px;
        @include border-radius(50%);
        background-color: $cprimary;
        position: absolute;
        animation: grow 1s ease-in-out infinite alternate;
        &.ball1 {
            left: 0;
            transform-origin: 100% 50%;
        }
        &.ball2 {
            left: 50%;
            @include transform(translateX(-50%) scale(1));
            animation-delay: 0.33s;
        }
        &.ball3 {
            right: 0;
            animation-delay: 0.66s;
        }
    }
}

.goRenoveCard {
    display: inline-flex;
    align-items: center;
    background: $cwhite;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    padding: 15px 40px 15px 20px;
    margin-bottom: 40px;
    &.onLoader {
        z-index: 11;
        position: absolute;
        left: 50%;
        top: 20%;
        transform: translate(-50%, -50%);
        width: 80%;
    }
    &Logo {
        min-width: 38px;
        width: 38px;
        margin-right: 18px;
    }
    &Infos {
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: $cprimary;
    }
}

@keyframes grow {
    to {
        @include transform(translateX(-50%) scale(0));
    }
}

@keyframes loaderSimple {
    0%, 20%, 80%, 100% {
        @include transform(scale(1));
        opacity: 0.1;
    }
    50% {
        @include transform(scale(1.3));
        opacity: 1;
    }
}

.popoverInfo {
    border: 0;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
    background: $cwhite;
    @include border-radius(10px);
    .popover-arrow {
        display: none;
    }
    &Button {
        font-size: 1.8rem;
        color: $csecondary;
        margin-left: 5px;
        cursor: pointer;
    }
    &Body {
        padding: 30px;
        &Icon {
            font-size: 1.8rem;
            color: $csecondary;
            margin-bottom: 10px;
            display: block;
        }
        &Details {
            font-size: 1.3rem;
            line-height: 2rem;
            font-weight: 500;
            color: $cprimary;
            margin-bottom: 0;
        }
    }
}
