.button {
  display: flex;
  align-items: center;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  padding: 10px 10px;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-width: 100px;
  justify-content: center;
  position: relative;
  overflow: hidden;
  &__label,
  &__icon {
    z-index: 9;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -10%;
    width: 120%;
    height: 100%;
    transform: skew(24deg);
    transition: all 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }
  &:hover:before {
    transform: translate3d(100%, 0, 0);
  }
  &:focus {
    border: 0;
    box-shadow: none;
  }

  &--primary {
    background: #e69707;
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
    &:not(:disabled):before {
      background: #f4a008;
    }
    &:not(:disabled):not(.disabled):active  {
      background: #f4a008;
      color: #ffffff;
      border: 0;
    }
  }
  &--secondary {
    background: #ffffff;
    color: #00556e;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &.dark {
      background: #00556e;
      color: #ffffff;
      box-shadow: none;
      .button__loading {
        border: 2px solid #ffffff;
      }
    }

    &:hover {
      background: #f4a008;
      color: #ffffff;
    }
  }
  &--tertiary {
    padding: 0;
    background: none;
    box-shadow: none;
    color: #00556e;
    font-weight: 400;
    font-size: 16px;
    text-decoration-line: underline;
    margin-bottom: 0;
    padding: 0;
    height: auto;
    border: none;
  }
  &--quaternary {
    padding: 0;
    background: none;
    box-shadow: none;
  }
  &--quinary {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    font-weight: 400;
    color: #00556e;
    font-size: 14px;
  }
  &--transparent {
    background: none;
    box-shadow: none;
    color: #00556e;
    margin-bottom: 0;
    min-width: auto;
    padding: 0;
    height: auto;
    border: none;
  }
  &--primary-border {
    background: none;
    border: 1px solid #e69707;
    color: #e69707;
    &:hover {
      background: #e69707;
      color: #ffffff;
    }
    .button__icon {
      svg {
        width: 18px;
      }
    }
  }
  &--secondary-border {
    background: none;
    border: 1px solid #00556e;
    color: #00556e;
    &:hover {
      background: #00556e;
      color: #ffffff;
    }
  }

  &--full {
    width: 100%;
    background-color: #00556e;
    border: 1px solid #00556e;
    color: #ffffff;
    height: 100%;

    &.verified {
      color: #23c275;
      border-color: #25c379;
      background: rgba(0, 128, 68, 0.04);

      box-si &:focus {
        border: 1px solid #25c379;
      }
    }
    &.not-verified {
      color: #db7927;
      border-color: #db7927;
      background: rgba(128, 58, 0, 0.04);
      &:focus {
        border: 1px solid #db7927;
      }
    }
  }

  &--small {
    font-size: 14px;
  }

  &--medium {
    font-size: 16px;
  }

  &--large {
    font-size: 18px;
  }

  &__icon {
    margin-right: 6px;
  }

  &__loading {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 9px solid #00556e;
    animation:
      spinner-bulqg1 0.8s infinite linear alternate,
      spinner-oaa3wk 1.6s infinite linear;
    margin-right: 6px;

    @keyframes spinner-bulqg1 {
      0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
      }

      12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
      }

      25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
      }

      50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
      }

      62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
      }

      75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
      }

      100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
      }
    }

    @keyframes spinner-oaa3wk {
      0% {
        transform: scaleY(1) rotate(0deg);
      }

      49.99% {
        transform: scaleY(1) rotate(135deg);
      }

      50% {
        transform: scaleY(-1) rotate(0deg);
      }

      100% {
        transform: scaleY(-1) rotate(-135deg);
      }
    }

    &--white {
      border: 2px solid #ffffff;
      border-top-color: transparent;
    }

    &--small {
      width: 15px;
      height: 15px;
    }

    &--medium {
      width: 20px;
      height: 20px;
    }

    &--large {
      width: 25px;
      height: 25px;
    }
  }

  &.disabled {
    background: #979797;
    cursor: not-allowed;
    .button__label {
      margin: auto;
    }

    .button__icon {
      display: none;
    }
  }
}

// Responsive styles
@media (max-width: 767px) {
  .button {
    font-size: 14px;
  }
}
