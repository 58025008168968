.register {
    display: flex;
    &Infos {
        width: 330px;
        min-width: 330px;
        min-height: calc(100vh - 70px);
        background: $cbgDarker;
        color: $cprimary;
        padding: 40px 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.diagnostic {
            background: $cprimary;
            color: $cwhite;
        }

        &Illu {
            img {
                width: 225px;
            }
        }
        &Title {
            font-size: 2rem;
            line-height: 2.4rem;
            font-weight: 700;
            margin-bottom: 10px;
            margin-top: 50px;
        }
        &Details {
            font-size: 1.4rem;
            line-height: 2.2rem;
            margin-bottom: 40px;
        }
        &List {
            list-style: none;
            padding: 0;
            margin: 0;
            &Item {
                display: flex;
                &:not(:last-child) {
                    margin-bottom: 30px;
                }
                &Icon {
                    width: 24px;
                    height: 24px;
                    color: $csecondary;
                    margin-right: 15px;
                    i {
                        font-size: 2.4rem;
                    }
                }
                &Label {
                    font-weight: 700;
                    font-size: 1.4rem;
                    line-height: 2rem;
                }
            }
        }
    }
    &Form {
        width: calc(100% - 330px);
        min-width: calc(100% - 330px);
        padding: 30px 100px;
        &Header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            &Login {
                text-align: right;
                display: flex;
                align-items: center;
                &Link {
                    text-decoration: underline;
                    font-weight: 700;
                    padding: 0;
                    border: 0;
                    background: transparent;
                    color: $cprimary;
                    &:hover {
                        color: $csecondary;
                    }
                }
                &Arrow {
                    margin-left: 10px;
                }
            }
        }
        &Step {
            display: flex;
            padding-bottom: 50px;
            &Item {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                &:not(:last-child) {
                    padding-right: 50px;
                    &:after {
                        content: "\e942";
                        font-family: 'icomoon' !important;
                        font-size: 0.15rem;
                        color: $ctertiary;
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        opacity: 0.2;
                    }
                }
                &.active {
                    &:after {
                        opacity: 1;
                    }
                    .registerFormStepItem {
                        &Number {
                            background: $ctertiary;
                            color: $cwhite;
                        }
                        &Label {
                            display: block;
                        }
                    }
                }
                &.validate {
                    &:after {
                        opacity: 1;
                    }
                    &:before {
                        content: "\e900";
                        font-family: 'icomoon' !important;
                        font-size: 1rem;
                        color: $cwhite;
                        position: absolute;
                        top: 50%;
                        left: 17px;
                        @include transform(translateY(-50%));
                    }
                    .registerFormStepItem {
                        &Number {
                            color: $ctertiary;
                            background: $ctertiary;
                        }
                        &Label {
                            display: block;
                        }
                    }
                }
                &Number {
                    width: 45px;
                    min-width: 45px;
                    height: 45px;
                    background: rgba(0, 155, 151, 0.15);
                    color: $ctertiary;
                    @include border-radius(50%);
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &Label {
                    font-size: 1.1rem;
                    line-height: 2rem;
                    color: $ctertiary;
                    position: absolute;
                    bottom: -25px;
                    width: 70px;
                    text-align: center;
                    display: none;
                }
            }
            &Content {
                max-width: 880px;
                &:not(.current) {
                    display: none;
                }
                &Title {
                    font-size: 3.2rem;
                    line-height: 3.2rem;
                    font-weight: 700;
                    margin-bottom: 20px;
                }
                &Details {
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    margin-bottom: 40px;
                }
                &Infos {
                    margin-bottom: 30px;
                    a {
                        text-decoration: underline;
                    }
                }
                &Cta {
                    margin-top: 20px;
                    .btn-tertiary {
                        margin-right: 10px;
                    }
                }
                &Confirm {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    max-width: 550px;
                    margin: 0 auto;
                    min-height: calc(100vh - 315px);
                    &Icon {
                        font-size: 5rem;
                        margin-bottom: 10px;
                        color: $ctertiary;
                    }
                    &Title {
                        font-size: 3.2rem;
                        line-height: 3.6rem;
                        font-weight: 700;
                        margin-bottom: 20px;
                    }
                    &Details {
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        color: $ctertiary
                    }
                }
            }
        }
    }
}
