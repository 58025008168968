.form {
  &__container {
    background: #ffffff;
    box-shadow:
      0px 8px 12px rgba(0, 0, 0, 0.08),
      0px 4px 48px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 30px 20px;

    margin-top: 40px;

    &.nospace {
      padding-left: 0;
      padding-right: 0;

      .form__container__title {
        padding-left: 20px;
        padding-right: 20px;
      }
      .form__container__content > div:first-child {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &__content__or {
      position: relative;
      &::before {
        content: '';
        width: 2px;
        height: 60%;
        background: #252525;
        margin: 2rem 0 2rem 0;
        left: 50%;
        position: absolute;
      }
      &--text {
        background-color: #ffffff;
        z-index: 1;
      }
    }

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      color: #00556e;
      margin-bottom: 20px;
    }

    &__content {
      &__header {
        display: flex;
        align-items: center;
        gap: 20px;

        &--avatar {
          border-radius: 16px;
          overflow: hidden;
        }
        &__content {
          &--name {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            color: #1d1d1d;
          }
          &--rge {
            background: rgba(245, 249, 250, 0.8);
            border: 1px solid #809da6;
            border-radius: 3px;
            padding: 2px;
            text-transform: capitalize;
            margin: 0.5rem 0;
          }
          &--actions {
            .button__label {
              font-weight: bold;
              font-size: 14px;
            }
          }
        }
      }

      &__body {
        margin: 4rem 0 4rem 0;

        &__item {
          background-color: #f0f3f5;
          max-width: 400px;
          margin: 1rem 0;
          padding: 5px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          gap: 5px;
          &--value {
            color: #7b8993;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
          }
        }
      }
    }
    .subcontractor_wrapper {
      display: flex;
      align-items: center;
      gap: 4rem;
    }
  }
}

.help {
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #00556e;
    margin: 30px 0;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #252525;
    margin-bottom: 20px;

    a {
      color: #252525;
      &:hover {
        color: #f4a008;
      }
    }
  }
}

// media queries
@media (max-width: 768px) {
  .subcontractor_wrapper {
    flex-direction: column;
    gap: 2rem;
  }
  .subcontractor_wrapper__image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
