.header__title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #00556e;
}
.header__subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 0;
  color: #00556e;
}
.projets {
  &__container {
    background: #ffffff;
    box-shadow:
      0px 8px 12px rgba(0, 0, 0, 0.08),
      0px 4px 48px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 40px 20px;
    margin-top: 40px;
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #00556e;
    margin-bottom: 30px;
  }
}
