.projets {
  &__stepper {
    display: flex;
    justify-content: space-between;
    max-width: 98%;
    margin: 20px auto;

    &__step {
      cursor: pointer;

      &__line {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #00556e;
        }
      }

      &:first-child .projets__stepper__step__line::before {
        left: 50%;
      }
      &:last-child .projets__stepper__step__line::before {
        left: -50%;
      }

      flex: 1;
      &__label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #252525;
        margin-top: 1rem;
        text-align: center;
        &.en_cours {
          color: #f4a008;
          font-weight: 700;
        }
      }
      &__progress {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        position: relative;
        margin: 0 auto;
        z-index: 9;

        &.non_commence {
          background-color: #ffffff;
          border: 1px solid #00556e;
        }
        &.en_cours {
          background-color: #f4a008;
          &::before {
            content: '';
            width: 20px;
            height: 20px;
            background: #f4a008;
            position: absolute;
            border-radius: 50%;
            top: -3px;
          }
        }
        &.incomplet {
          background-color: #ff0000;
        }
        &.rejete {
          background-color: #ff0000;
        }
        &.termine {
          background-color: #00556e;
        }
      }
    }
  }

  &__note {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 2rem 0;

    &--text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #252525;
      padding-top: 4px;
    }
  }
}

/**
 * Media query for screens with a maximum width of 768px.
 */
@media (max-width: 768px) {
  .projets {
    &__stepper {
      &__step {
        &__label {
          display: none;
        }
      }
    }
    &__note {
      &--text {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #00556e;
      }
    }
  }
}
