@media screen and (max-width: 1399px) {
  .diagnosticResult {
    &PerfEnergyItem {
      &[data-letter='A'] {
        bottom: 170.5px;
      }
      &Infos {
        font-size: 1.1rem;
        line-height: 1.4rem;
        strong:first-child {
          font-size: 1.4rem;
        }
      }
    }
    &BannerInfosAnchors.sticky .diagnosticBannerInfosAnchorsContainer {
      max-width: 1140px;
    }
  }
}

@media screen and (max-width: 1199px) {
  .diagnostic {
    &Chapter {
      min-width: 250px;
      padding: 50px 30px;
    }
    &Question {
      min-width: calc(100% - 250px);
      padding: 40px 70px 20px;
      &List.displayColumn .diagnosticQuestionListItem {
        font-size: 1.3rem;
        &Content {
          padding: 40px 20px 20px;
        }
      }
      &.coordinate {
        padding: 0 50px 0 0;
      }
      &CoordinateRight {
        margin-left: 50px;
      }
    }
    &Footer {
      &Save {
        margin-left: 10px;
      }
    }
    &Result {
      &BannerInfosAnchors.sticky .diagnosticResultBannerInfosAnchorsContainer {
        max-width: 960px;
      }
      &Energy {
        &Label {
          margin-right: 15px;
        }
        &Infos {
          font-size: 1.3rem;
          strong {
            font-size: 1.4rem;
          }
        }
      }
      &Bloc {
        &HeaderTopLabel {
          margin-left: -80px;
        }
        .diagnosticResult {
          &Intro {
            max-width: 100%;
          }
          &Title {
            margin-top: 0;
          }
        }
      }
      &Modal {
        .modal {
          &-dialog {
            width: 90%;
          }
          &-header {
            padding: 60px 40px 0;
          }
          &-body {
            padding: 25px 40px 60px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .diagnostic {
    &Question {
      padding: 30px 50px 55px;
      &.coordinate {
        padding: 30px 50px 55px;
        & > .diagnosticQuestionLabel {
          display: block;
          & + .diagnosticQuestionWhy {
            display: flex;
          }
        }
      }
      &Coordinate {
        &Container {
          flex-direction: column;
        }
        &Map {
          position: relative;
          bottom: inherit;
          align-self: auto;
          &Element {
            height: 80vw;
            max-height: 350px;
          }
        }
        &Right {
          margin-left: 0;
          .diagnosticQuestionLabel,
          .diagnosticQuestionWhy {
            display: none;
          }
        }
      }
      &CatLink {
        display: flex;
      }
      &List {
        &.displayColumn {
          .diagnosticQuestionListItem {
            width: calc((100% - 20px) / 2);
            padding-bottom: calc((100% - 20px) / 2);
          }
        }
        &Children {
          padding: 25px 50px;
        }
        &ItemOccupant {
          flex-direction: column;
          &Increment {
            margin-bottom: 20px;
          }
        }
      }
      &Start {
        &Intro {
          flex-direction: column-reverse;
          margin-bottom: 40px;
          &Text {
            margin-top: 40px;
            margin-right: 0;
            &Title {
              font-size: 2.4rem;
              line-height: 2.6rem;
              margin-bottom: 20px;
            }
          }
          &Illu {
            text-align: center;
          }
        }
      }
    }
    &Rejected {
      flex-direction: column;
      align-items: center;
      &Icon {
        padding-top: 0;
        padding-right: 0;
        margin-bottom: 30px;
        img {
          width: 40vw;
        }
      }
    }
    &Result {
      &Title {
        font-size: 3rem;
      }
      &Intro {
        font-size: 1.6rem;
        line-height: 2.3rem;
      }
      &Perf {
        padding: 45px 0;
        div[class^='col']:not(:last-child) {
          margin-bottom: 50px;
        }
      }
      &Bloc {
        &Price {
          font-size: 2rem;
        }
        &BodyEnergy {
          margin-bottom: 30px;
        }
      }
      &Banner {
        &Infos {
          &Title {
            font-size: 2rem;
            line-height: 2.8rem;
          }
          &Anchors {
            &Right {
              margin-left: 30px;
            }
            &.sticky .diagnosticResultBannerInfosAnchorsContainer {
              max-width: 720px;
            }
            &ListItemLink {
              padding: 14px;
            }
          }
        }
        &Illu {
          width: 170px;
          min-width: 170px;
          margin-right: 30px;
        }
      }
      &SaveTitle {
        flex: 1;
        font-size: 1.6rem;
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .loaderEnd {
    &Container {
      min-height: auto;
    }
    &Title {
      font-size: 2.4rem;
      line-height: 2.6rem;
    }
    &List {
      &Item  {
        font-size: 1.3rem;
        &Icon {
          i.icon-glassloader {
            font-size: 2rem;
          }
        }
      }
    }
  }
  .ballLoader {
    width: 20px;
    height: 5px;
    margin-bottom: 5px;
    &Ball {
      height: 5px;
      width: 5px;
    }
  }
  .diagnostic {
    padding-bottom: 50px;
    flex-direction: column;
    position: relative;
    &.start {
      .diagnostic {
        &Chapter {
          display: none;
          &.primes {
            display: block;
          }
        }
        &Footer {
          height: auto;
          padding: 1rem 0;
        }
      }
    }
    &Chapter {
      width: 100%;
      height: 50px;
      padding: 10px 35px;
      border-top: 1px solid rgba(0, 85, 110, 0.1);
      border-bottom: 1px solid rgba(0, 85, 110, 0.1);
      overflow: hidden;
      top: 55px;
      z-index: 2;
      &Prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 48px;
        background: $cwhite;
        color: $cprimary;
        position: absolute;
        left: 0;
        top: 1px;
        border: 0;
      }
      &List {
        display: none;
        &Mobile {
          display: flex;
          align-items: center;
          justify-content: center;
          list-style: none;
          padding: 0;
          margin: 0;
          &Item {
            background: rgba(0, 155, 151, 0.15);
            min-width: 28px;
            width: 28px;
            height: 28px;
            @include border-radius(50%);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 18px;
            position: relative;
            &:last-child {
              margin-right: 0;
              &:after {
                display: none;
              }
            }
            &:after {
              content: '';
              width: 8px;
              height: 2px;
              @include border-radius(1px);
              background: $ctertiary;
              margin: 5px;
              position: absolute;
              right: -18px;
              top: 8px;
            }
            &.completed {
              background: $ctertiary;
              color: $cwhite;
              i {
                font-size: 0.9rem;
              }
            }
          }
        }
      }
    }
    &Question {
      min-width: 100%;
      &Label {
        font-size: 2.4rem;
        line-height: 2.6rem;
      }
      &Why {
        &Title {
          font-size: 1.4rem;
          line-height: 2rem;
        }
        &Details {
          font-size: 1.3rem;
          line-height: 2rem;
        }
        &Icon {
          font-size: 3rem;
        }
      }
      &List {
        &:not(.displayColumn) {
          .diagnosticQuestionListItem {
            min-height: 55px;
            font-size: 1.3rem;
            line-height: 1.6rem;
            &Content {
              padding: 10px 20px 10px 60px;
            }
            &:before {
              width: 22px;
              height: 22px;
            }
            &.selected:after {
              width: 22px;
              height: 22px;
            }
          }
        }
        &Item.increment .diagnosticQuestionListItemPicto {
          margin-bottom: 3vw;
          i {
            font-size: 11vw;
          }
        }
      }
    }
    &Rejected {
      padding: 50px 30px;
      &Content {
        &Title {
          font-size: 2.4rem;
          line-height: 2.6rem;
          &Sub {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-bottom: 30px;
          }
        }
      }
    }
    &Footer {
      padding: 0;
      &Cgu {
        padding: 10px 12px;
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-right: 0;
        flex: 1;
      }
      &Save {
        flex: 1;
        justify-content: center;
        height: 100%;
        margin-left: 0;
        i {
          font-size: 2rem;
        }
      }
      &Actions {
        flex: 1;
        height: 50px;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 152px;
          &.btn-icon-left {
            margin-right: 0;
          }
        }
      }
      &SaveLabel {
        text-decoration: none;
        font-size: 1.3rem;
        span {
          display: none;
        }
      }
    }
    &Result {
      &Title {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
      &Perf {
        &Title:not(.smallForMobile) {
          font-size: 2rem;
          line-height: 2.2rem;
        }
      }
      &Energy {
        &Label {
          margin-right: 8px;
        }
        &Infos {
          font-size: 1.2rem;
          strong {
            font-size: 1.3rem;
          }
        }
      }
      &Recommendation {
        display: none;
      }
      &Intro {
        font-weight: normal;
      }
      &Bloc {
        .diagnosticResult {
          &Title {
            font-size: 2rem;
            line-height: 2.2rem;
          }
          &Intro {
            font-weight: normal;
          }
        }
        &Header {
          padding: 35px 25px;
          &Top {
            flex-direction: column;
            &Gaz {
              align-items: flex-start;
              margin-top: 30px;
            }
            &Label {
              margin-left: -50px;
              &First {
                font-size: 2rem;
              }
              &Second {
                font-size: 1.4rem;
              }
            }
          }
        }
        &Body {
          padding: 35px 25px;
        }
        &Price {
          margin-bottom: 30px;
        }
      }
      &Banner {
        background: $cwhite;
        position: relative;
        color: $cprimary;
        padding-bottom: 40px;
        &:before {
          content: '';
          width: 100%;
          height: 230px;
          z-index: 0;
          position: absolute;
          left: 0;
          top: 0;
          background: url('../../images/bg-result-mobile.svg') no-repeat $cwhite;
          background-position: bottom;
          background-size: cover;
        }
        &Container {
          flex-direction: column;
          position: relative;
        }
        &Illu {
          width: 230px;
          min-width: 230px;
        }
        &Date {
          position: relative;
          right: inherit;
          bottom: inherit;
          max-width: 540px;
          margin: 0 auto;
          padding: 0 9px;
          margin-top: 30px;
        }
        &Infos {
          margin-top: 30px;
          &Title {
            font-size: 2.4rem;
            line-height: 2.8rem;
          }
          &Anchors {
            &Right {
              margin-left: 45px;
            }
            &.sticky .diagnosticResultBannerInfosAnchorsContainer {
              max-width: 540px;
            }
            &ListItemLink {
              background: $cbg;
            }
          }
        }
      }
      &Save {
        &Title {
          display: none;
        }
        &Cta {
          width: 100%;
          justify-content: center;
        }
      }
      &TableWorks {
        &Check {
          align-items: flex-end;
          padding: 0 20px;
          i.icon-validate-on {
            font-size: 2rem;
          }
        }
        thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }

        tr {
          border-bottom: 3px solid #ccc;
          display: block;
          margin-bottom: 0.625em;
          td {
            border-bottom: 1px solid #ccc;
            display: block;
            text-align: right;
            min-height: 30px;
            &:not([data-label='work']):before {
              content: attr(data-label);
              float: left;
              font-weight: bold;
              margin-left: 20px;
              margin-top: 6px;
              font-size: 1.2rem;
              font-weight: normal;
            }
            &:first-child {
              border-bottom: 0;
              padding: 10px 20px;
              background: $cprimary !important;
              color: $cwhite !important;
              font-size: 1.3rem;
            }
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 65px;
    }
    50% {
      bottom: 70px;
    }
  }
}

@media screen and (max-width: 575px) {
  .diagnostic {
    &.start {
      padding-bottom: 130px;
      .diagnostic {
        &Footer {
          flex-direction: column-reverse;
          padding: 15px 0;
          &Cgu {
            margin: 15px 15px 15px 15px;
            text-align: left;
          }
          &Actions .btn.btn-icon-left {
            height: 50px;
            font-size: 1.4rem;
            @include border-radius(10px);
          }
        }
      }
    }
    &Question {
      padding: 30px;
      .diagnosticQuestion {
        padding: 0;
      }
      &.coordinate {
        padding: 30px;
      }
      &List {
        &.displayColumn {
          .diagnosticQuestionListItem {
            width: 100%;
            padding-bottom: 100%;
            margin-right: 0;
            &:nth-child(2n) {
              margin-right: 0;
            }
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
        }
        &Children {
          padding: 25px 30px 25px 60px;
          .diagnosticQuestionListItem {
            margin-bottom: 0;
          }
        }
        &ItemOccupant {
          &Increment {
            width: 100%;
            min-width: 100%;
          }
          .diagnosticQuestionListItem {
            width: 100%;
            min-width: 100%;
            margin: 0;
            &Increment {
              width: 100%;
            }
          }
        }
        &Item.increment {
          .diagnosticQuestionListItem {
            &Content {
              font-size: 1.6rem;
              font-weight: 700;
              line-height: 2rem;
            }
            &Picto {
              margin-bottom: 8vw;
              i {
                font-size: 22vw;
              }
            }
          }
        }
      }
      &Start {
        &Intro {
          &Text {
            padding-left: 0;
          }
          img {
            max-width: 150px;
          }
        }
        &Item {
          flex-direction: column;
          &Icon {
            margin-bottom: 15px;
            img {
              max-width: 30px;
            }
          }
          &Content {
            margin-left: 0;
            &Title,
            &Details {
              font-size: 1.3rem;
              line-height: 2rem;
            }
          }
        }
      }
      &Modal {
        padding-left: 0 !important;
        .modal-dialog {
          margin: 0;
        }
        &ListItem {
          height: 0;
          padding-bottom: 100%;
          width: 100%;
          background: $cbg;
          &Picto {
            width: 100%;
            height: 0;
            padding-bottom: 85%;
            position: relative;
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              height: 85%;
              @include transform(translate(-50%, -50%));
            }
          }
          &Label {
            font-size: 1.6rem;
            margin-top: 5px;
          }
        }
      }
    }
    &Rejected {
      padding: 20px 30px 30px;
      &Content {
        &Phone {
          font-size: 2rem;
          line-height: 2.4rem;
        }
        &Form {
          &Cta {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .btn {
              width: 100%;
              justify-content: center;
              margin: 5px auto;
            }
          }
          &Checkbox {
            flex-wrap: wrap;
            &Label {
              min-width: 100%;
              margin-bottom: 15px;
              font-size: 1.3rem;
            }
          }
        }
      }
    }
    &Result {
      .container:not(.diagnosticResultBannerContainer) {
        padding: 0 30px;
      }
      &Modal {
        .modal {
          &-dialog {
            margin: 0 auto;
          }
          &-header {
            padding: 25px 20px 0;
          }
          &-body {
            padding: 25px 20px 20px;
          }
        }
      }
      &Banner {
        &Container {
          padding: 0 0 0 30px;
        }
        &Date {
          max-width: 100%;
          padding: 0 30px;
        }
        &Infos {
          max-width: 100%;
          &Title {
            padding-right: 30px;
          }
          &Anchors {
            overflow-x: auto;
            &.sticky {
              padding: 10px 30px;
            }
          }
        }
      }
      &Bloc {
        .diagnosticResultIntro {
          font-size: 1.4rem;
          line-height: 2.2rem;
        }
        &Header {
          &TopPrice {
            width: 100%;
            min-width: 100px;
          }
          &BadgeItem {
            font-size: 1.3rem;
            line-height: 1.3rem;
          }
        }
        &Body {
          padding: 35px 20px;
          &Footer {
            font-size: 1.4rem;
            line-height: 1.8rem;
          }
          &Cta {
            margin: 40px 0 20px;
            &Title {
              font-size: 2rem;
              line-height: 2.4rem;
            }
          }
          &Title {
            font-size: 1.6rem;
            &Container {
              margin-bottom: 30px;
            }
          }
          &Works {
            margin-bottom: 0;
            &Details {
              margin-top: 10px;
            }
          }
          &Energy {
            padding-left: 0;
            &Label {
              font-size: 1.1rem;
              line-height: 1.1rem;
              position: relative;
              &[data-letter='A'] {
                color: $clettera;
                top: 4;
                left: 0;
              }
              &[data-letter='B'] {
                color: $cletterb;
                top: 4;
                left: 0;
              }
              &[data-letter='C'] {
                color: $cletterc;
                top: 4;
                left: 0;
              }
              &[data-letter='D'] {
                color: $cletterd;
                top: 4;
                left: 0;
              }
              &[data-letter='E'] {
                color: $clettere;
                top: 4;
                left: 0;
              }
              &[data-letter='F'] {
                color: $cletterf;
                top: 4;
                left: 0;
              }
              &[data-letter='G'] {
                color: $cletterg;
                top: 0;
                left: 0;
              }
            }
          }
          .diagnosticResultEnergy {
            flex-direction: column-reverse;
            align-items: flex-start;
            .diagnosticResultEnergyInfos {
              margin-bottom: 3px;
              min-width: max-content;
              font-size: 1.1rem;
              line-height: 1.3rem;
            }
            .diagnosticResultBlocBodyEnergyLabel {
              font-size: 1.1rem;
            }
            &[data-letter='A'] {
              left: 118px;
            }
            &[data-letter='B'] {
              left: 132px;
            }
            &[data-letter='C'] {
              left: 145px;
            }
            &[data-letter='D'] {
              left: 158px;
            }
            &[data-letter='E'] {
              left: 172px;
            }
            &[data-letter='F'] {
              left: 185px;
            }
            &[data-letter='G'] {
              left: 198px;
            }
          }
        }
      }
    }
    &Footer {
      height: auto;
      padding: 20px 0 60px;
      &Cgu {
        padding: 4px 12px;
        font-size: 1.2rem;
        line-height: 2rem;
        flex: 1;
      }
      &Actions .btn {
        padding: 0 10px;
        font-size: 1.2rem;
      }
    }
  }
  .burgerMenuBottom {
    position: fixed;
    bottom: -20px;
    right: -15px;
    background: #fff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    z-index: 999;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-bottom: 6px;
  }
}

@media screen and (max-width: 374px) {
  .diagnosticChapter {
    padding: 10px;
    &.displayPrev {
      padding-left: 60px;
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input,
  .diagnosticQuestionText div[class$='-Input'] {
    font-size: 16px;
  }
}
