.dashboard {
  background-color: #f2f6f8;
  &__content {
    padding: 30px 4% 30px;
    width: 78%;
    min-height: 100vh;
    margin-left: auto;

    @media (max-width: 991px) {
      padding: 30px 15px 0;
      width: 100%;
      height: 100%;
      margin: auto;
    }
  }
}
