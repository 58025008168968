.demande__details {
  &__content {
    margin-top: 6rem;
    &__title {
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: #00556e;
    }
    &__estimatif {
      background: #ffffff;
      border-radius: 20px;
      padding: 20px;
      margin: 2rem 0;
      display: flex;
      align-items: center;
      gap: 2rem;
      &__description {
        flex: 0.9;
        display: flex;
        align-items: center;

        a {
          color: #00556e;
          text-decoration: underline;
        }

        &::before {
          content: '?';
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          color: #ad0e0e;
          border: 1px solid #ad0e0e;
          margin-right: 10px;
          position: relative;
          font-size: 20px;
          text-align: center;
          font-weight: 900;
          flex-shrink: 0;
        }
      }
      &__prime {
        background: #00556e;
        border-radius: 10px;
        color: #ffffff;
        text-align: left;
        font-style: normal;
        font-weight: 700;
        padding: 15px;
        min-width: 210px;
        flex: 0.3;
        &__value {
          h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
          }
        }
      }
    }
  }
}

// reponsive design
@media (max-width: 768px) {
  .demande__details {
    &__content {
      &__estimatif {
        flex-direction: column-reverse;
        gap: 1rem;
        &__description {
          flex: 1;
          width: 100%;
        }
        &__prime {
          flex: 1;
          margin-top: 1rem;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
