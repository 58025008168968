@import '~react-circular-progressbar/dist/styles.css';

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Gilroy', arial;
  font-size: 1.4rem;
  font-weight: 500;
  color: $cprimary;
  &.no-scroll {
    overflow: hidden;
  }
}

a {
  color: $cprimary;
  text-decoration: none;
  &:hover {
    color: $csecondary;
  }
}

::placeholder {
  color: rgba(0, 85, 110, 0.3);
  font-weight: 600;
  font-size: 1.4rem;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

label {
  font-size: 1.2rem;
  display: block;
}

button.link {
  display: inline;
  border: none;
  background-color: inherit;
  color: inherit;
  text-decoration: underline;
  margin: inherit;
  padding: inherit;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
input[type='tel'] {
  background: $cbg;
  color: $cprimary;
  height: 60px;
  width: 100%;
  border: 0;
  padding: 0 30px;
  font-weight: 600;
  font-size: 1.4rem;
  &.withError {
    border: 2px solid $cerror;
  }
}

.rounded {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  input[type='tel'] {
    background: #ffffff;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 50px;
    &.withError {
      box-shadow: none;
    }
  }
}

.custom__date {
  label {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    .form-group-label {
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
    .form-group-input {
      margin: 0;
      input {
        background: #ffffff;
        border: 0.1px solid rgb(37 37 37 / 46%);
        box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        min-height: 40px;
        padding: 0 10px;
        color: #9a9a9a;
        font-weight: 500;
        font-style: italic;
        font-size: 16px;
        font-family: 'Gilroy';
      }
    }
    .formError {
      width: 100%;
    }
  }
}

textarea {
  @extend input, [type='text'];
  padding: 20px 30px;
  height: 180px;
}

.form {
  &-group {
    &.disabled {
      .form-group-label,
      .customSelect select,
      textarea,
      input[type='text'],
      input[type='email'],
      input[type='password'],
      input[type='number'],
      input[type='tel'] {
        color: rgba(0, 85, 110, 0.3);
      }
    }
    &-input {
      position: relative;
      margin-top: 10px;
      margin-bottom: 25px;
      &.withIcon {
        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='number'],
        input[type='tel'] {
          padding-right: 50px;
        }
      }
      .form-group-input-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        @include transform(translateY(-50%));
        font-size: 2.2rem;
        &.icon-eye {
          font-size: 1.2rem;
        }
      }
    }
  }
  &Error {
    color: $cerror;
    display: flex;
    align-items: center;
    margin-top: -15px;
    margin-bottom: 20px;
    &Label {
      font-size: 1.2rem;
      font-weight: 700;
      margin-left: 10px;
    }
    i {
      font-size: 1.6rem;
    }
  }
}

.custom {
  &Select {
    position: relative;
    margin-top: 10px;
    margin-bottom: 30px;
    select {
      @extend input, [type='text'];
      -webkit-appearance: none;
      -moz-appearance: none;
      margin: 0;
    }
    &:after {
      content: '\e901';
      font-family: 'icomoon';
      color: $ctertiary;
      font-size: 0.8rem;
      position: absolute;
      right: 20px;
      top: 50%;
      @include transform(translateY(-50%));
    }
    &.withError {
      border: 2px solid $cerror;
    }
  }
  &Checkbox {
    margin-bottom: 25px;
    &.inline {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
    &.rounded {
      .customCheckboxLabel {
        &:before {
          background: #ffffff;
          border: 2px solid #d9d9d9;
          border-radius: 8px;
        }
      }
    }
    &.withError {
      .customCheckboxLabel {
        color: $cerror;
        font-weight: 700;
        &:before {
          border: 2px solid $cerror;
        }
      }
    }
    &Label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-size: 1.4rem;
      display: flex;
      &.with-tooltip {
        display: inline;
      }
      a {
        text-decoration: underline;
      }
      &:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        min-width: 20px;
        height: 20px;
        background: $cwhite;
        border: 1px solid $cprimary;
      }
      &:after {
        content: '\e900';
        font-family: 'icomoon';
        position: absolute;
        top: 5px;
        left: 6px;
        color: $cwhite;
        font-size: 0.7rem;
      }
    }

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      &:checked + .customCheckboxLabel {
        &:before {
          background: $cprimary;
        }
      }
    }
  }
}

.icon-prev {
  @extend .icon-next;
  @include transform(rotate(180deg));
}

.btn {
  @include border-radius(10px);
  height: 50px;
  display: inline-flex;
  align-items: center;
  border: 0;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0 18px;
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -10%;
    width: 120%;
    height: 100%;
    @include transform(skew(24deg));
    @include transition(all 0.4s cubic-bezier(0.3, 1, 0.8, 1));
  }
  &:hover:before {
    @include transform(translate3d(100%, 0, 0));
  }
  &:focus {
    border: 0;
    box-shadow: none;
  }
  span {
    position: relative;
    display: flex;
    align-items: center;
  }
  i {
    font-size: 2rem;
  }
  &-icon {
    &-right {
      i {
        margin-left: 10px;
      }
    }
    &-left {
      i {
        margin-right: 10px;
      }
    }
  }
  &.btn-link {
    color: $cprimary;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 500;
    height: auto;
    i {
      font-size: 1.6rem;
    }
    span {
      text-decoration: underline;
    }
    &:hover {
      color: $csecondary;
    }
  }
  &.btn-primary {
    background: $csecondaryDarker;
    color: $cwhite;
    border: 2px solid $csecondary;
    &:hover {
      color: $cwhite;
    }
    &:before {
      background: $csecondary;
    }
    &:not(:disabled):not(.disabled):active  {
      background: $csecondary;
      color: $cwhite;
      border: 0;
    }
  }
  &.btn-secondary {
    background: $cbg;
    color: $cprimary;
    border: 2px solid $cprimary;
    &:hover {
      color: $cprimary;
    }
    &:before {
      background: $cwhite;
    }
    &:not(:disabled):not(.disabled):active  {
      background: $cwhite;
      color: $cprimary;
      border: 0;
    }
  }
  &.btn-tertiary {
    background: $cbgDarker;
    color: $cprimary;
    border: 2px solid $cbg;
    &:hover {
      color: $cprimary;
    }
    &:before {
      background: $cbg;
    }
    &:not(:disabled):not(.disabled):active  {
      background: $cbg;
      color: $cprimary;
      border: 0;
    }
  }
}

.link-underline a {
  text-decoration: underline !important;
}

.alert {
  @include border-radius(0);
  &-success {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 20px 0;
    color: $csuccess;
    font-size: 1.4rem;
    font-weight: bold;
    &:before {
      content: '\e929';
      font-family: 'Icomoon' !important;
      display: inline-block;
      vertical-align: middle;
      margin-right: 13px;
      font-size: 2.4rem;
      font-weight: normal;
    }
  }
  &-warning {
    background: $cbg;
    border: 0;
    padding: 10px 20px;
    margin: 20px 0;
    color: $csuccess;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.content {
  padding-top: 70px;
}

/*
* CSS Google optimize
*/
.goptimize-mask-show {
  height: 100vh;

  .goptimize-mask {
    display: flex;
  }

  .diagnosticResult {
    .diagnosticResultBloc,
    .diagnosticResultRecommendation {
      display: none;
    }

    .diagnosticResultPerf {
      .row {
        max-height: 39vh;
      }
    }
  }
}

.goptimize-mask {
  width: 100%;
  position: relative;
  bottom: 40vh;
  z-index: 100;
  display: none;
  flex-wrap: wrap;

  .goptimize-mask-transparent {
    width: 100%;
    height: 100px;
    background: linear-gradient(#ffffff4d, #ffffffd6);
  }

  .goptimize-mask-content {
    padding: 5%;
    background-color: white;
    filter: drop-shadow(0px 20px 20px black);
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    .goptimize-mask-title {
      font-weight: 650;
      font-size: 3.6rem;
      margin-bottom: 40px;
      width: 100%;
    }

    .goptimize-mask-button {
      padding: 0 50px;
      font-size: 1.8rem;
    }
  }
}

.goptimize-picto-box {
  width: 100%;
  margin: 0 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .goptimize-picto-item {
    width: 33%;
  }
}
