// Typography.scss
.custom-typography {
  &.variant-h1 {
    font-size: 2.5rem;
  }

  &.variant-h2 {
    font-size: 2rem;
  }

  &.variant-h3 {
    font-size: 1.75rem;
  }

  &.size-xs {
    font-size: 0.75rem;
  }

  &.size-s {
    font-size: 0.875rem;
  }

  &.size-m {
    font-size: 1rem;
  }

  &.color-darkorange {
    color: darkorange;
  }
  &.color-black {
    color: #252525;
  }

  &.font-weight-normal {
    font-weight: normal;
  }

  &.font-weight-bold {
    font-weight: bold;
  }

  &.font-weight-bolder {
    font-weight: bolder;
  }

  &.font-weight-lighter {
    font-weight: lighter;
  }

  &.font-weight-500 {
    font-weight: 500;
  }

  &.font-weight-600 {
    font-weight: 600;
  }

  &.font-weight-700 {
    font-weight: 700;
  }

  &.font-weight-800 {
    font-weight: 800;
  }

  &.font-weight-900 {
    font-weight: 900;
  }

  @media screen and (max-width: 600px) {
    &.variant-h1 {
      font-size: 2rem;
    }
    &.variant-h2 {
      font-size: 1.5rem;
    }
    &.variant-h3 {
      font-size: 1.25rem;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    &.variant-h1 {
      font-size: 2.25rem;
    }
    &.variant-h2 {
      font-size: 1.75rem;
    }
    &.variant-h3 {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    &.variant-h1 {
      font-size: 2.5rem;
    }
    &.variant-h2 {
      font-size: 2rem;
    }
    &.variant-h3 {
      font-size: 1.75rem;
    }
  }

  @media screen and (min-width: 1441px) {
    &.variant-h1 {
      font-size: 3rem;
    }
    &.variant-h2 {
      font-size: 2rem;
    }
    &.variant-h3 {
      font-size: 1.5rem;
    }
  }
}
