.dashboard__sideBar {
  background-color: #ffffff;
  margin-bottom: 0rem;
  padding: 4rem 4rem;
  height: 100vh;
  width: 22%;
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 100;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  // style the scroll bar like apple devices
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 10px;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// Responsive design

// large devices
@media (max-width: 1400px) {
  .dashboard__sideBar {
    padding: 4rem 2rem;
  }
}

// medium devices
@media (max-width: 991px) {
  .dashboard__sideBar {
    display: none;
  }
}
