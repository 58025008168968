/* Define variables for colors */
$primaryColor: #08556d;
$secondaryColor: #00556e;
$backgroundColor: #f2f8fa;
$whiteColor: #ffffff;
$shadowColor: rgba(0, 0, 0, 0.05);
$darkTextColor: #000000;
$orangeColor: #f4a008;

/* Global Section */
.result {
  padding: 4rem 0 0 0;
  margin: 0 auto;
  overflow: hidden;

  &--loading {
    background-color: $secondaryColor;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  /* Presentation Section */
  &__presentation {
    text-align: center;
    padding: 2rem;

    /* Title and Description in Presentation Section */
    &-title,
    &-description {
      font-style: normal;
    }

    &-title {
      font-weight: 800;
      font-size: 50px;
      color: $primaryColor;
    }

    &-description {
      margin-top: 2rem;
      font-weight: 400;
      font-size: 16px;
      color: $secondaryColor;
    }
  }

  &__prime-wrapper {
    padding: 2rem;
    max-width: 900px;
    margin: auto;
  }
  /* Prime Amount Section */
  &__prime-amount {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    /* Value in Prime Amount Section */
    &-value {
      padding: 2rem;
      position: relative;

      h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 80px;
        color: $secondaryColor;
      }

      &::after {
        content: '';
        background: $secondaryColor;
        width: 3px;
        height: 70%;
        position: absolute;
        top: 50%;
        right: 0%;
        transform: translate(-50%, -50%);
      }
    }

    /* Text in Prime Amount Section */
    &-text {
      padding: 2rem;

      /* Title and Description in Text Section */
      &-title,
      &-description {
        font-style: normal;
      }
      &-description {
        &::before {
          content: 'i';
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          color: #ad0e0e;
          border: 1px solid #ad0e0e;
          margin-right: 10px;
          position: relative;
          font-size: 12px;
          text-align: center;
          font-weight: 900;
        }
      }

      &-title {
        font-weight: 700;
        font-size: 30px;
        color: $primaryColor;
        margin-bottom: 0;
      }

      &-description {
        font-weight: 500;
        font-size: 12px;
        color: $darkTextColor;
      }
    }

    /* Actions in Prime Amount Section */
    &-actions {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;

      &-btn {
        outline: none;
        border: none;
        background: $orangeColor;
        color: $whiteColor;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        padding: 1rem 2rem;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: darken($orangeColor, 10%);
        }
      }
    }

    /* Links in Prime Amount Section */
    &-link {
      margin: 0 2rem;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      text-decoration-line: underline;
      color: $primaryColor;
    }
  }

  .result-prime__progression {
    display: flex;
    height: 6px;
    width: 100%;
    background-color: #505050;
    border-radius: 5px;
    overflow: hidden;
  }

  .result-prime__progression-reste {
    background-color: #505050; /* resteACharge */
  }

  .result-prime__progression-mpr {
    background-color: #009b97; /* montantMpr */
  }

  .result-prime__progression-prime {
    background-color: #f4a008; /* montantPrimeEnergie */
  }

  .result-prime {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    &__title,
    &__estimate,
    &__subtitle {
      font-weight: 700;
      color: #08556d;
    }
    &__subtitle {
      margin-top: 1rem;
    }
    &__title {
      font-size: 1.875rem;
    }
    &__estimate {
      font-size: 3.125rem;
    }

    &__progression {
      margin-bottom: 20px;
    }

    &__details {
      padding: 10px 0;
    }

    &__detail {
      display: flex;
      margin: 4rem 0;
      gap: 4rem;

      &-square {
        height: 28px;
        width: 37px;
        border-radius: 5px;
        &.orange {
          background-color: #f4a008;
        }
        &.green {
          background-color: #009b97;
        }
        &.dark {
          background-color: #505050;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        &-additional-info {
          a {
            color: $orangeColor;
            text-decoration: underline;
            &:hover {
              color: darken($orangeColor, 10%);
            }
          }
          // before icon
          &::before {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            color: #ad0e0e;
            border: 1px solid #ad0e0e;
            margin-right: 10px;
            position: relative;
            font-size: 12px;
            text-align: center;
            font-weight: 900;
          }
          &.info-icon {
            &::before {
              content: 'i';
            }
          }
          &.question-icon {
            &::before {
              content: '?';
            }
          }
        }
      }

      &-info {
        display: flex;
        gap: 4rem;
        align-items: center;

        &.orange {
          color: #f4a008;
        }
        &.green {
          color: #009b97;
        }
        &.dark {
          color: #505050;
        }

        &-amount {
          font-size: 3rem;
          font-weight: bold;
          line-height: 1;
          min-width: 100px;
        }
        &-description {
          font-size: 2rem;
          font-weight: 700;
        }
      }
      &-actions {
        display: flex;
        flex-direction: column;
      }

      &-amount {
        font-size: 1.2rem;
        font-weight: bold;
        color: #28a745;
      }

      &-description {
        font-size: 0.9rem;
        color: #666;
      }
    }
  }

  &__non-cumulable {
    .tooltip-container {
      width: 100%;
    }
    .tooltip-text {
      left: 100%;
    }
    max-width: 278px;
    background: #ecebeb;
    border-radius: 10px;
    padding: 10px;
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      h3 {
        font-weight: 900;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 0;
      }
    }
    &-description {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
  }

  /* Mention Section */
  &__mention {
    margin: 2rem auto 5rem auto;
    text-align: center;
    max-width: 1000px;
    padding: 0 2rem;

    /* Description in Mention Section */
    &-description {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: $primaryColor;
    }
  }

  /* Project Section */
  &__project {
    background: $backgroundColor;
    border-radius: 100px 100px 0px 0px;
    padding: 4rem 2rem 8rem 2rem;
    height: 100%;

    /* Title in Project Section */
    &-title {
      text-align: center;

      h3 {
        font-style: normal;
        font-weight: 800;
        font-size: 36px;
        text-align: center;
        color: $primaryColor;
      }
    }

    /* Content in Project Section */
    &-content {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      max-width: 1300px;
      margin: 0 auto;
      margin-top: 8rem;
      justify-content: space-between;
    }

    /* Info in Project Section */
    &-info {
      flex: 1;
      background: $whiteColor;
      box-shadow: 0px 4px 30px $shadowColor;
      border-radius: 10px;
      padding: 2rem;

      /* Picto in Info Section */
      &-picto {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: $primaryColor;
        margin: 0 auto;
        margin-top: -5rem;
      }

      /* Title in Info Section */
      &-title {
        text-align: center;
        margin: 2rem;

        h4 {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: $primaryColor;
        }
      }
    }
  }
}

// media queries for responsive design

@media (max-width: 1024px) {
  .result {
    &__presentation {
      &-title {
        font-size: 40px;
      }
      &-description {
        font-size: 14px;
      }
    }
    &__prime-amount {
      &-value {
        h2 {
          font-size: 40px;
        }
      }
      &-text {
        &-title {
          font-size: 20px;
        }
      }
    }

    &__mention {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .result {
    padding: 2rem 0 0 0;
    &__presentation {
      &-title {
        font-size: 30px;
      }
    }
    &__prime-amount {
      flex-direction: column;
      align-items: center;
      margin-left: 0;

      &-value {
        padding: 0;
        &::after {
          display: none;
        }
      }

      &-text {
        text-align: center;
      }

      &-actions {
        margin-top: 2rem;
      }
    }
    &__non-cumulable {
      .tooltip-text {
        left: 50%;
      }
    }
    &__project {
      &-title {
        h3 {
          font-size: 30px;
        }
      }
      &-content {
        flex-direction: column;
      }
      &-info {
        &-picto {
          margin-top: 0;
        }
      }
    }
    // result prime section
    .result-prime {
      &__detail {
        flex-direction: column;
        gap: 1rem;
        margin: 20px 0;
        &-square {
          width: 20px;
          height: 20px;
        }
        &-content {
          gap: 1rem;
        }
        &-info {
          gap: 1rem;
          &-amount {
            font-size: 2rem;
            min-width: auto;
          }
          &-description {
            font-size: 1.5rem;
          }
        }
        // actions
        &-actions {
          max-width: 300px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .result {
    &__presentation {
      &-title {
        font-size: 20px;
      }
    }
    &__prime-amount {
      &-actions {
        flex-direction: column;
      }
      &-link {
        margin-top: 2rem;
      }
      &-text {
        padding: 0;
      }
    }
    &__project {
      &-title {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}
