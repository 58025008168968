@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy/Gilroy-Bold.eot');
    src: url('../../fonts/Gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
        url('../../fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
        url('../../fonts/Gilroy/Gilroy-Bold.ttf') format('truetype'),
        url('../../fonts/Gilroy/Gilroy-Bold.svg#Gilroy-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy/Gilroy-SemiBold.eot');
    src: url('../../fonts/Gilroy/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2'),
        url('../../fonts/Gilroy/Gilroy-SemiBold.woff') format('woff'),
        url('../../fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype'),
        url('../../fonts/Gilroy/Gilroy-SemiBold.svg#Gilroy-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy/Gilroy-Medium.eot');
    src: url('../../fonts/Gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Gilroy/Gilroy-Medium.woff2') format('woff2'),
        url('../../fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
        url('../../fonts/Gilroy/Gilroy-Medium.ttf') format('truetype'),
        url('../../fonts/Gilroy/Gilroy-Medium.svg#Gilroy-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/Icomoon/icomoon.eot?dln086');
  src:  url('../../fonts/Icomoon/icomoon.eot?dln086#iefix') format('embedded-opentype'),
    url('../../fonts/Icomoon/icomoon.ttf?dln086') format('truetype'),
    url('../../fonts/Icomoon/icomoon.woff?dln086') format('woff'),
    url('../../fonts/Icomoon/icomoon.svg?dln086#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-north:before {
  content: "\e96b";
}
.icon-concerto:before {
  content: "\e96c";
}
.icon-air-quality:before {
  content: "\e944";
}
.icon-cloud:before {
  content: "\e962";
}
.icon-cold-wall:before {
  content: "\e963";
}
.icon-easy:before {
  content: "\e964";
}
.icon-ecology:before {
  content: "\e965";
}
.icon-heat:before {
  content: "\e966";
}
.icon-outside:before {
  content: "\e967";
}
.icon-simple:before {
  content: "\e968";
}
.icon-thermal-bridge:before {
  content: "\e969";
}
.icon-globale:before {
  content: "\e96a";
}
.icon-deal:before {
  content: "\e95f";
}
.icon-support:before {
  content: "\e961";
}
.icon-good:before {
  content: "\e95d";
}
.icon-address:before {
  content: "\e929";
}
.icon-euro-simple:before {
  content: "\e94f";
}
.icon-snow:before {
  content: "\e954";
}
.icon-air:before {
  content: "\e955";
}
.icon-bad:before {
  content: "\e956";
  color: #c52b47;
}
.icon-data:before {
  content: "\e957";
}
.icon-home:before {
  content: "\e958";
}
.icon-list:before {
  content: "\e959";
}
.icon-question-circle:before {
  content: "\e95a";
}
.icon-size:before {
  content: "\e95b";
}
.icon-stats:before {
  content: "\e95c";
}
.icon-vmc:before {
  content: "\e95e";
}
.icon-warning:before {
  content: "\e960";
  color: #c52b47;
}
.icon-facebook:before {
  content: "\e950";
}
.icon-free:before {
  content: "\e951";
}
.icon-smiley:before {
  content: "\e952";
}
.icon-twitter:before {
  content: "\e953";
}
.icon-arrow-up:before {
  content: "\e94c";
}
.icon-paginate-next:before {
  content: "\e94d";
}
.icon-paginate-prev:before {
  content: "\e94e";
}
.icon-filters:before {
  content: "\e94b";
}
.icon-add:before {
  content: "\e945";
}
.icon-download:before {
  content: "\e946";
}
.icon-edit:before {
  content: "\e947";
}
.icon-search:before {
  content: "\e948";
}
.icon-send:before {
  content: "\e949";
}
.icon-validate:before {
  content: "\e94a";
}
.icon-calendar:before {
  content: "\e943";
}
.icon-smiley:before {
  content: "\e944";
}
.icon-error:before {
  content: "\e93f";
}
.icon-eye:before {
  content: "\e940";
}
.icon-lock:before {
  content: "\e941";
}
.icon-minus:before {
  content: "\e942";
}
.icon-minus:before {
  content: "\e93f";
}
.icon-info:before {
  content: "\e93d";
}
.icon-justice:before {
  content: "\e93e";
}
.icon-menu:before {
  content: "\e93c";
}
.icon-logout:before {
  content: "\e93b";
}
.icon-plus:before {
  content: "\e916";
}
.icon-date:before {
  content: "\e928";
}
.icon-down:before {
  content: "\e934";
}
.icon-loading:before {
  content: "\e939";
}
.icon-trash:before {
  content: "\e93a";
}
.icon-validate-on:before {
  content: "\e933";
}
.icon-setting:before {
  content: "\e930";
}
.icon-checklist:before {
  content: "\e931";
}
.icon-glassloader:before {
  content: "\e932";
}
.icon-cost:before {
  content: "\e935";
}
.icon-settings:before {
  content: "\e936";
}
.icon-temp:before {
  content: "\e937";
}
.icon-work:before {
  content: "\e938";
}
.icon-question-round:before {
  content: "\e92f";
}
.icon-check:before {
  content: "\e900";
}
.icon-chevron-down:before {
  content: "\e901";
}
.icon-chevron-prev:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-hourglass:before {
  content: "\e904";
}
.icon-star-on:before {
  content: "\e905";
}
.icon-account:before {
  content: "\e906";
}
.icon-calculator:before {
  content: "\e907";
}
.icon-chatbot:before {
  content: "\e908";
}
.icon-coal:before {
  content: "\e909";
}
.icon-confort:before {
  content: "\e90a";
}
.icon-diagnostic:before {
  content: "\e90b";
}
.icon-electricity:before {
  content: "\e90c";
}
.icon-environment:before {
  content: "\e90d";
}
.icon-evolution:before {
  content: "\e90e";
}
.icon-family:before {
  content: "\e90f";
}
.icon-faucet:before {
  content: "\e910";
}
.icon-fuel:before {
  content: "\e911";
}
.icon-gaz:before {
  content: "\e912";
}
.icon-ground:before {
  content: "\e913";
}
.icon-group:before {
  content: "\e914";
}
.icon-heater:before {
  content: "\e915";
}
.icon-hot-water:before {
  content: "\e917";
}
.icon-Individuel:before {
  content: "\e918";
}
.icon-invoices:before {
  content: "\e919";
}
.icon-keys:before {
  content: "\e91a";
}
.icon-network:before {
  content: "\e91b";
}
.icon-next:before {
  content: "\e91c";
}
.icon-noise:before {
  content: "\e91d";
}
.icon-phone:before {
  content: "\e91e";
}
.icon-pin:before {
  content: "\e91f";
}
.icon-play:before {
  content: "\e920";
}
.icon-previous:before {
  content: "\e921";
}
.icon-question:before {
  content: "\e922";
}
.icon-roof:before {
  content: "\e923";
}
.icon-save:before {
  content: "\e924";
}
.icon-science:before {
  content: "\e925";
}
.icon-security:before {
  content: "\e926";
}
.icon-sun:before {
  content: "\e927";
}
.icon-validate:before {
  content: "\e929";
}
.icon-valorize:before {
  content: "\e92a";
}
.icon-walls:before {
  content: "\e92b";
}
.icon-why:before {
  content: "\e92c";
}
.icon-windows:before {
  content: "\e92d";
}
.icon-wood:before {
  content: "\e92e";
}
