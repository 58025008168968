.block {
  display: block !important;
}
.flex {
  display: flex !important;
}

.none {
  display: none !important;
}

.header {
  padding: 0 40px;
  border-bottom: 1px solid $cbg;
  height: 70px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  background: $cwhite;
  &Menu {
    display: none;
  }
  &Logo {
    margin: 0 auto;
  }
  &Nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 5vw;
    &Burger {
      width: 22px;
      height: 16px;
      position: relative;
      cursor: pointer;
      @include transition(0.5s ease-in-out);
      display: none;
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $cprimary;
        opacity: 1;
        left: 0;
        @include border-radius(2px);
        @include transform(rotate(0deg));
        @include transition(0.25s ease-in-out);
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 7px;
        }
        &:nth-child(4) {
          top: 14px;
        }
      }
      &.open {
        span {
          &:nth-child(1) {
            top: 7px;
            width: 0%;
            left: 50%;
          }
          &:nth-child(2) {
            @include transform(rotate(45deg));
          }
          &:nth-child(3) {
            @include transform(rotate(-45deg));
          }
          &:nth-child(4) {
            top: 7px;
            width: 0%;
            left: 50%;
          }
        }
      }
    }
    &Left {
      list-style: none;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      margin: 0;
      padding: 0;
      &Item {
        margin: 0 2vw;
        &:hover {
          .headerNavLeftItemLink {
            color: $ctertiary;
            text-decoration: none;
            text-shadow:
              0 0 0.25px $ctertiary,
              0 0 0.25px $ctertiary;
            &:after {
              width: 100%;
            }
          }
          .headerNavLeftItemChildren {
            display: flex;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &Link {
          color: $cprimary;
          height: 70px;
          display: flex;
          align-items: center;
          position: relative;
          transition: text-shadow 0.3s;
          cursor: pointer;
          &:after {
            content: '';
            width: 0;
            height: 3px;
            background: $ctertiary;
            position: absolute;
            bottom: -1px;
            left: 0;
            @include transition(all 0.5s);
          }
          &:hover {
            color: $ctertiary;
            text-decoration: none;
            text-shadow:
              0 0 0.25px $ctertiary,
              0 0 0.25px $ctertiary;
            &:after {
              width: 100%;
            }
          }
        }
        &Children {
          position: absolute;
          left: 0;
          top: 70px;
          width: 100%;
          background: $cwhite;
          justify-content: space-between;
          min-height: 300px;
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.05);
          display: none;
          transition: all 0.3s;
          z-index: 10;
          &Action {
            color: $cwhite;
            background: url('../../images/menu-bg.svg') $cprimary no-repeat;
            background-size: contain;
            background-position: left bottom;
            width: 25%;
            min-width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 25px 20px;
            text-align: center;
            .btn {
              padding: 0 15px;
            }
            &Title {
              font-size: 1.8rem;
              line-height: 2.4rem;
              font-weight: 700;
              margin-bottom: 15px;
              max-width: 255px;
            }
            &Illu {
              margin-top: 20px;
            }
          }
          &Cat {
            &Container {
              width: 75%;
              min-width: 75%;
              display: flex;
              & > div {
                width: calc(100% / 3);
                min-width: calc(100% / 3);
              }
            }
            padding: 30px 40px 40px;
            &:not(:last-of-type) {
              border-right: 1px solid rgba(0, 0, 0, 0.2);
            }
            &Title {
              color: $ctertiary;
              font-weight: 700;
              font-size: 2rem;
              line-height: 2.4rem;
              display: flex;
              align-items: flex-start;
              margin-bottom: 20px;
              &Icon {
                min-width: 25px;
                width: 30px;
                margin-right: 20px;
                text-align: right;
                i {
                  font-size: 3rem;
                  &.icon-diagnostic {
                    font-size: 2.2rem;
                  }
                  &.icon-roof {
                    font-size: 2.8rem;
                  }
                  &.icon-work,
                  &.icon-concerto,
                  &.icon-euro-simple {
                    font-size: 2.5rem;
                  }
                }
              }
            }
            &List {
              padding: 0 0 0 50px;
              margin: 0;
              list-style: none;
              &Item {
                &:not(:last-child) {
                  margin-bottom: 20px;
                }
                &Link {
                  color: $cprimary;
                  font-size: 1.4rem;
                  line-height: 2rem;
                  &:hover {
                    color: $ctertiary;
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }
      .forMobile {
        display: none;
      }
    }
    &Right {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      list-style: none;
      margin: 0;
      padding: 0;
      &Item {
        margin: 0 2vw;
        height: 100%;
        &Link {
          color: $cprimary;
          display: flex;
          align-items: center;
          height: 70px;
          position: relative;
          cursor: pointer;
          background: transparent;
          border: 0;
          padding: 0;
          &:after {
            content: '';
            width: 0;
            height: 2px;
            background: $csecondary;
            position: absolute;
            bottom: 0;
            left: 0;
            @include transition(all 0.5s);
          }
          &:hover {
            text-decoration: none;
            color: $csecondary;
            &:after {
              width: 100%;
            }
          }
          i {
            font-size: 2rem;
            margin-right: 15px;
            &.icon-down {
              font-size: 1rem;
              margin-right: 0;
              margin-left: 15px;
              &.active {
                @include transform(rotate(180deg));
              }
            }
          }
          svg {
            margin-left: 4px;
          }
        }
        &.headerNavRightItemDiagnostic {
          margin-left: 0;
          .headerNavRightItemLink {
            font-weight: bold;
            color: $csecondary;
            padding: 0 10px 0 5px;
            span {
              position: relative;
              z-index: 2;
              display: flex;
              align-items: center;
              i {
                margin-right: 10px;
              }
            }
            // &:hover {
            //     color: $cwhite;
            //     &:after {
            //         height: 100px;
            //         z-index: 1;
            //     }
            // }
            // &:after {
            //     width: 100%;
            // }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
