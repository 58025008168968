.dashboard {
  &__header {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      color: #00556e;
    }
    &__profile {
      &__name {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        color: #f4a008;
      }
    }
  }
  &__banner {
    margin-top: 4rem;
    img {
      width: 100%;
      border-radius: 15px;
    }
  }
}

@media (max-width: 768px) {
  .dashboard {
    &__header {
      h1 {
        font-size: 24px;
      }
      &__profile {
        &__name {
          font-size: 32px;
        }
      }
    }
    &__banner {
      margin-top: 2rem;
      img {
        width: 100%;
        border-radius: 15px;
      }
    }
  }
}

// tablet view
@media (max-width: 1024px) {
  .dashboard {
    &__header {
      h1 {
        font-size: 20px;
      }
      &__profile {
        &__name {
          font-size: 24px;
        }
      }
    }
    &__banner {
      margin-top: 2rem;
      img {
        width: 100%;
        border-radius: 15px;
      }
    }
  }
}
