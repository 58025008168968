@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

// Browser Prefixes
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Apparence
@mixin apparence() {
  -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

// Rotate
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
    	-webkit-transform-origin: $origin;
    	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}

// Animation
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin animation($animation...) {
  -o-animation: $animation;
  -moz-animation: $animation;
  -webkit-animation: $animation;
  animation: $animation;
}

//placeholder
@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}

//text-shadow
@mixin text-shadow($params) {
  -webkit-text-shadow: $params;
     -moz-text-shadow: $params;
          text-shadow: $params;
}

//box-sizing
@mixin border-box() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//filter
@mixin filter($type,$amount) {
  -webkit-filter: $type+unquote('(#{$amount})');
  -moz-filter: $type+unquote('(#{$amount})');
  -ms-filter: $type+unquote('(#{$amount})');
  -o-filter: $type+unquote('(#{$amount})');
  filter: $type+unquote('(#{$amount})');
}

// multi columns
@mixin columns($value) {
	-moz-columns: $value;
	-webkit-columns: $value;
	columns: $value;
}

@mixin column-count($value) {
  -webkit-column-count: $value;
  -moz-column-count: $value;
  column-count: $value;
}

@mixin column-gap($value) {
  -webkit-column-gap: $value;
  -moz-column-gap: $value;
  column-gap: $value;
}


// placeholder color
@mixin placeholder($color, $size:"") {
  &::-webkit-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &::-moz-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
  &:-ms-input-placeholder {
    color: $color;
    @if $size != "" {
      font-size: $size;
    }
  }
}

// width max-content
@mixin max-content() {
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}
